'use client';

import OfferCard from '@/newComponents/OfferCard/OfferCard';
import { OfferListBlock, Product } from '@/types';
import { useState } from 'react';

type OfferListProps = {
  offerListBlock: OfferListBlock;
  itemListName: string;
  selectedFilter: string;
  productsData: { data: Product[] };
  renderDetails?: boolean;
  userId: string | null;
};

const OfferList = (props: OfferListProps) => {
  const { renderDetails, offerListBlock, itemListName, selectedFilter, productsData, userId } = props;
  const { offerListName } = offerListBlock;
  const { offers } = offerListBlock.offerList;
  const [selectedOffer, setSelectedOffer] = useState(offers[0].content.offer?.ratorPackageId);

  const mobileBrowserClickHandler = (ratorPackageId: string) => {
    setSelectedOffer(ratorPackageId);
  };
  const [cardContentHeights, setCardContentHeights] = useState<Record<number, number>>({});

  const setCardContentHeight = (id: number, height: number) => {
    setCardContentHeights((prev) => ({ ...prev, [id]: height }));
  };

  const heighestCardContent = Object.values(cardContentHeights).reduce((prev, curr) => Math.max(prev, curr), 0);
  const regularSurfInfoExists = offers.some((offer) => offer.content.offer?.regularSurfAmount);

  return (
    <>
      {offers.map((offer) => {
        const { onlyDisplayShortlisted } = offerListBlock;
        const { offer: offerContent, shortlisted } = offer.content;
        if (!offerContent) return null;
        if (onlyDisplayShortlisted && !shortlisted) return null;

        return (
          <OfferCard
            key={`${offerContent.id}`}
            offerCallout={offer.content.offerCallout}
            data={offerContent}
            highlight={offer.content.highlight}
            type={offerListName}
            itemListName={itemListName}
            selectedFilter={selectedFilter}
            productsData={productsData}
            selected={offerContent.ratorPackageId === selectedOffer}
            mobileBrowserClickHandler={mobileBrowserClickHandler}
            renderDetails={renderDetails}
            setCardContentHeight={(height) => setCardContentHeight(offerContent.id, height)}
            regularSurfInfoExists={regularSurfInfoExists}
            heighestCardContent={heighestCardContent}
            userId={userId}
          />
        );
      })}
    </>
  );
};

export default OfferList;
