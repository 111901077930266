import { Color } from '@/global/style/variables';
import { StickyUspBannerThemeKey } from '@/types';

export const getTheme = (theme: StickyUspBannerThemeKey) => {
  if (theme === StickyUspBannerThemeKey.DarkHallon) {
    return {
      backgroundColor: Color.Hallon1,
      textColor: Color.Hallon5,
    };
  }
  if (theme === StickyUspBannerThemeKey.LightHallon) {
    return {
      backgroundColor: Color.Hallon6,
      textColor: Color.Hallon1,
    };
  }
  if (theme === StickyUspBannerThemeKey.UsedHardware) {
    return {
      backgroundColor: Color.Nyanza,
      textColor: Color.MineShaft,
    };
  }
};
