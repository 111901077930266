import Divider from '@/newComponents/Divider/Divider';
import IconWithLabel from '@/newComponents/IconWithLabel/IconWithLabel';
import SvgIcon, { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import React, { ReactNode } from 'react';

import { spacing } from '@/global/style/variables';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import { StyledToggleInfo, UspWrapper } from './OfferCardBottomDetails.styled';

type OfferCardBottomDetailsProps = {
  header: string;
  body: ReactNode;
  listItems: { label: string; icon: IconKey }[];
  expansionCallback?: (expanded: boolean) => void;
  hasUnlimitedData: boolean;
};

const OfferCardBottomDetails = (props: OfferCardBottomDetailsProps) => {
  const { hasUnlimitedData, header, body, listItems, expansionCallback } = props;

  if (hasUnlimitedData) {
    return (
      <StyledToggleInfo expansionCallback={expansionCallback} header={header} data-testid="offer-card-toggle-info">
        <Typography data-testid="offer-card-detail-body" type={TypographyType.Detail}>
          Med ett obegränsat abonnemang kan du surfa och streama så mycket du vill.
        </Typography>
      </StyledToggleInfo>
    );
  }

  return (
    <StyledToggleInfo expansionCallback={expansionCallback} header={header} data-testid="offer-card-toggle-info">
      <Typography data-testid="offer-card-detail-body" type={TypographyType.Detail} text={body} />
      <Divider />
      <UspWrapper>
        {listItems.map((item, index) => (
          <React.Fragment key={item.label}>
            <IconWithLabel
              label={
                <Typography
                  data-testid={`offer-card-detail-${index}`}
                  type={TypographyType.Detail}
                  text={<HtmlFromCMS html={item.label} />}
                />
              }
              icon={<SvgIcon icon={item.icon} size="small" />}
            />
            <Divider marginY={spacing.x05} />
          </React.Fragment>
        ))}
      </UspWrapper>
    </StyledToggleInfo>
  );
};

export default OfferCardBottomDetails;
