import styled from 'styled-components';

import { Color } from '@/global/style/variables';
import { above } from '@/global/style/breakpoint';

export const BannerflowWrapper = styled.div<{ $desktopHeight: string; $mobileHeight: string }>`
  display: block;
  position: relative;
  height: auto;
  > div {
    display: block !important;
  }

  aspect-ratio: 480 / ${({ $mobileHeight }) => $mobileHeight};

  ${above.sm`
      aspect-ratio: 1400 / ${({ $desktopHeight }) => $desktopHeight};
  `}
`;

export const Wrapper = styled.section<{ $backgroundColor?: string }>`
  position: relative;
  background-color: ${({ $backgroundColor }) => `#${$backgroundColor}` || Color.Hallon1};
`;

/**
 * BannerflowHeader and BannerflowPreamble are used for Google search bot indexing purposes only.
 */
export const BannerflowHeader = styled.h1<{ $color?: string }>`
  color: ${({ $color }) => `#${$color}` || Color.Hallon1};
  max-height: 0;
  margin: 0;
  z-index: -1;
`;
export const BannerflowPreamble = styled.p<{ $color?: string }>`
  color: ${({ $color }) => `#${$color}` || Color.Hallon1};
  max-height: 0;
  margin: 0;
  z-index: -1;
`;
