'use client';

import React, { useRef } from 'react';
import classNames from 'classnames';
import type { MiniTeasersBlock as MiniTeasersBlockType } from '@/types';

import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import { getMiniTeasersCmsTheme } from '@/global/utils/Cms';

import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import Divider from '@/newComponents/Divider/Divider';
import { spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import MiniTeaser from './MiniTeaser/MiniTeaser';
import styles from './MiniTeaserBlock.module.css';

type MiniTeasersBlockProps = {
  data: MiniTeasersBlockType;
  imageHasPriority?: boolean;
  firstBlock: boolean;
};

const MiniTeasersBlock = (props: MiniTeasersBlockProps) => {
  const { data, imageHasPriority = false, firstBlock } = props;
  const { theme: themeKey } = data;
  const ref = useRef<HTMLElement>(null);

  const teaserBlockHeaders = data.teaserBlocks
    .map((teaser) => teaser.content?.header)
    .filter(Boolean)
    .join('|');

  useTrackPromotionViewed(ref, data.$type, teaserBlockHeaders);

  const theme = getMiniTeasersCmsTheme(themeKey);
  const containerStyles = {
    '--mini-teaser-block-bg-color': theme.backgroundColor,
  } as React.CSSProperties;

  return (
    <section className={styles.miniTeaserBlock} style={containerStyles} ref={ref}>
      <div>
        {data.header && (
          <>
            <Typography
              elementAs={firstBlock ? 'h1' : 'h2'}
              text={data.header}
              type={TypographyType.HeadingM}
              fontWeight={FontWeight.Medium}
              align="center"
            />
            <Divider marginY={spacing.x15} marginYSm={spacing.x2} />
          </>
        )}

        <ul
          className={classNames(styles.miniTeaserBlockInner, {
            [styles.justifyStart]: data.teaserBlocks.length === 4,
          })}
        >
          {data.teaserBlocks.map((teaser, index) => (
            <MiniTeaser
              key={teaser.content.header}
              teaser={teaser}
              index={index}
              theme={theme}
              data={data}
              teaserBlockHeaders={teaserBlockHeaders}
              imageHasPriority={imageHasPriority}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default MiniTeasersBlock;
