import LazyLoadingImage from '@/newComponents/LazyLoadingImage/LazyLoadingImage';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BackgroundGradient } from '@/global/style/globalStyles';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const ContentWrapper = styled.div<{
  $backgroundImage: boolean;
  $backgroundColor: string;
  $enableGradient: boolean;
}>`
  z-index: 1;
  isolation: isolate;
  position: relative;
  display: grid;
  place-content: end;
  justify-content: center;
  border-radius: ${NewBorderRadius.Medium};
  overflow: hidden;
  height: 100%;

  padding: ${spacing.x2};

  ${above.xs`
    padding: ${spacing.x3};
  `}

  ${above.md`
    padding: ${spacing.x4};
  `}

  ${above.lg`
    padding: ${spacing.x5};
  `}

  background-color: ${({ $backgroundColor, $backgroundImage }) =>
    $backgroundColor && !$backgroundImage ? $backgroundColor : `${Color.Transparent}`};
  ${({ $backgroundImage, $enableGradient }) =>
    $backgroundImage &&
    $enableGradient &&
    css`
      ${BackgroundGradient}
      &::after {
        z-index: -1;
      }
    `};
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
`;

export const StyledImage = styled(LazyLoadingImage)`
  object-fit: cover;
  z-index: -1;
`;
