'use client';

import Button from '@/newComponents/Button/Button';
import BlockWrapper from '@/newComponents/CmsBlocks/Common/BlockWrapper/BlockWrapper';
import { HalfWidthContainer } from '@/newComponents/CmsBlocks/Common/HalfWidthContainer/HalfWidthContainer.styled';
import HeaderWithIngress from '@/newComponents/CmsBlocks/Common/HeaderWithIngress/HeaderWithIngress';
import Divider from '@/newComponents/Divider/Divider';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import type {
  ApiResponseError,
  BlockItemProps,
  CmsSettings,
  HalfWidthTeaserBlock as HalfWidthTeaserBlockCmsType,
} from '@/types';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import { useGetProduct } from '@/global/hooks/useGetProduct';
import useToastNotice from '@/global/hooks/useToastNotice';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import { getBlockWithStickerCmsTheme } from '@/global/utils/Cms';

import { ButtonWrapper, ContentWrapper, StyledImage } from './HalfWidthTeaserBlock.styled';

type HalfWidthTeaserBlockProps = {
  className?: string;
  data: HalfWidthTeaserBlockCmsType;
} & BlockItemProps;

const HalfWidthTeaserBlock = (props: HalfWidthTeaserBlockProps) => {
  const { data, className, userId } = props;
  const {
    $type,
    backgroundImage,
    header,
    link,
    offerReference,
    onlyTheButtonIsLinked,
    subheader,
    theme: themeKey,
    enableGradient = true,
  } = data;
  const { account } = useAppContext();
  const { type: userBrand } = account || {};
  const ref = useRef<HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const theme = getBlockWithStickerCmsTheme(themeKey);
  const { backgroundColor, transparentSecondaryButton, foregroundColor } = theme || {};
  const productData = useGetProduct(offerReference?.ratorPackageId);
  const offerItem = offerReference && productData && generateGtmLineItemFromRaw(offerReference, productData);
  const router = useRouter();
  const toast = useToastNotice();
  const { utmTracking, salesTracking } = useTracking();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;

  const shouldAddToCart = offerReference && onlyTheButtonIsLinked && productData;
  const wrapperClickable = !onlyTheButtonIsLinked && link?.url;

  useTrackPromotionViewed(ref, $type, header, !!data?.offerReference, offerItem);

  const addProductToCart = async () => {
    try {
      if (!offerReference || !productData) {
        throw new Error('Missing offer or product data');
      }

      setIsLoading(true);

      await addToCart({
        offer: offerReference,
        productData,
        brand: productData.brand,
        userId,
        utmTracking,
        salesTracking,
      });

      router.push('/checkout');
    } catch (error) {
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if ($type && header) {
      gtmSelectPromotion($type, header, offerItem || null);
    }

    if (shouldAddToCart) {
      return addProductToCart();
    }

    router.push(link.url);
  };

  const blockHasLinkOrOffer = link?.url || offerReference;

  return (
    <HalfWidthContainer ref={ref} className={className}>
      <BlockWrapper clickable={wrapperClickable} link={link}>
        <ContentWrapper
          $backgroundImage={Boolean(backgroundImage?.url)}
          $backgroundColor={backgroundColor}
          $enableGradient={enableGradient}
        >
          <HeaderWithIngress header={header} ingress={subheader} textColor={foregroundColor} />
          <Divider />
          {data.buttonText && blockHasLinkOrOffer && (
            <ButtonWrapper>
              <Divider />
              <Button
                label={data.buttonText}
                color={data.buttonType}
                onClick={handleButtonClick}
                data-testid="halfwidth-teaser-block-button"
                isLoading={isLoading}
                transparentBackground={transparentSecondaryButton}
              />
            </ButtonWrapper>
          )}
          {backgroundImage && (
            <StyledImage
              src={`${process.env.CMS_RETURN_URL}${backgroundImage?.url}`}
              alt={backgroundImage?.name}
              fill
            />
          )}
        </ContentWrapper>
      </BlockWrapper>
    </HalfWidthContainer>
  );
};

export default HalfWidthTeaserBlock;
