import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Offer } from '@/types';

import { getCmsTextWithValues } from '@/global/utils/Cms';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import { StyledTypography } from './PreviousCampaign.styled';

type PreviousCampaignPrice = {
  className?: string;
  offer: Offer;
};

const PreviousCampaignPrice = (props: PreviousCampaignPrice) => {
  const { offer, className = '' } = props;
  return offer?.previousCampaignPrice?.length > 0 ? (
    <StyledTypography
      className={className}
      align="center"
      type={TypographyType.Detail}
      text={<HtmlFromCMS html={getCmsTextWithValues(offer.previousCampaignPrice)} />}
    />
  ) : null;
};

export default PreviousCampaignPrice;
