import { Wrapper } from '@/newComponents/CmsBlocks/Common/TextButtonWrapper/TextButtonWrapper.styled';
import React from 'react';

type TextButtonWrapperProps = {
  children: React.ReactNode;
  textPosition?: 'Left' | 'Right';
  alignSelf?: 'start' | 'center' | 'end' | 'baseline';
};

const TextButtonWrapper = ({ children, textPosition, alignSelf = 'center' }: TextButtonWrapperProps) => {
  return (
    <Wrapper $textPosition={textPosition} $alignSelf={alignSelf}>
      {children}
    </Wrapper>
  );
};

export default TextButtonWrapper;
