import { LegalBlock as LegalBlockType } from '@/types';
import HardwareProductCampaign from '@/pagewrappers/Hardware/HardwareProductPage/HardwareProductCampaign/HardwareProductCampaign';
import styles from './LegalBlock.module.css';
import { getTheme } from './LegalBlock.utils';

type LegalBlockProps = {
  data?: LegalBlockType | null;
};

const LegalBlock = (props: LegalBlockProps) => {
  const { data } = props;
  const { header, body, collapsedBody, expandedLabel, collapsedLabel, theme } = data || {};

  const { backgroundColor, hoverVariant } = getTheme(theme);

  return (
    <section style={{ background: backgroundColor }}>
      <div className={styles.legalBlock}>
        <HardwareProductCampaign
          header={header}
          body={body}
          specialDealCollapsedBody={collapsedBody}
          specialDealExpandedLabel={expandedLabel}
          specialDealCollapsedLabel={collapsedLabel}
          backgroundColor={backgroundColor}
          hoverVariant={hoverVariant}
        />
      </div>
    </section>
  );
};

export default LegalBlock;
