import Button from '@/newComponents/Button/Button';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { ApiResponseError, BlockItemProps, Brand, CmsSettings, Offer, Product, ProductRatePlan } from '@/types';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useState } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';
import useToastNotice from '@/global/hooks/useToastNotice';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import { gbToMusicTime, gbToVideoTime, getAmountGbNumber, surfDataPretty } from '@/global/utils/Calculate';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import { formatDisplayPrice } from '@/global/utils/Format';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import OffersJson from '@/pages/api/cms/offers.json';

import {
  BottomUspText,
  ButtonWrapper,
  CardContainer,
  DataUspHeading,
  DataUspList,
  DataUspListItem,
  DataUspListItemTextWrapper,
  DataUspSubheading,
  DetailContainer,
  ExtraUspWrapper,
  FinePrint,
  FinePrintWrapper,
  IconWrapper,
  OfferUspWrapper,
  PreviousCampaignPriceWrapper,
  Price,
  StyledCard,
  StyledMediaIcon,
  TextWrapper,
  Usp,
} from './DetailedOfferCard.styled';
import PreviousCampaignPrice from './PreviousCampaignPrice';

const renderCorrectDataAmount = (cmsData: Offer, amountGB: number, productsData?: { data: Product[] }) => {
  const { offers } = OffersJson;

  if (!productsData || !cmsData) return '';

  return (
    <>
      <DataUspListItem>
        <StyledMediaIcon icon="music" />
        <DataUspListItemTextWrapper>
          <Typography
            type={TypographyType.Detail}
            text={
              <HtmlFromCMS
                html={getCmsTextWithValues(offers.offerCards?.mediaInfoTextMusic, {
                  gbToMusicTime: gbToMusicTime(amountGB),
                })}
              />
            }
          />
        </DataUspListItemTextWrapper>
      </DataUspListItem>
      <DataUspListItem>
        <StyledMediaIcon icon="video" />
        <Typography
          type={TypographyType.Detail}
          text={
            <HtmlFromCMS
              html={getCmsTextWithValues(offers.offerCards?.mediaInfoTextVideo, {
                gbToVideoTime: gbToVideoTime(amountGB),
              })}
            />
          }
        />
      </DataUspListItem>
    </>
  );
};

const RenderDataEuAmount = ({ dataEuAmount }: { dataEuAmount: number }) => {
  const [html, setHtml] = useState<string | undefined>(undefined);
  const { offers } = OffersJson;

  useEffect(() => {
    setHtml(getCmsTextWithValues(offers.offerCards.dataEuAmountText, { dataEuAmount: dataEuAmount.toString() }));
  }, [dataEuAmount, offers]);

  if (dataEuAmount === 0) return null;

  return (
    <FinePrintWrapper>
      <IconWrapper>
        <SvgIcon icon="aeroplane" color={Color.Hallon1} size="small" />
      </IconWrapper>
      <FinePrint>
        <Typography type={TypographyType.Detail} text={<HtmlFromCMS html={html} />} />
      </FinePrint>
    </FinePrintWrapper>
  );
};

type DetailedOfferCardProps = {
  cmsData?: Offer;
  productsData?: { data: Product[] };
} & BlockItemProps;

const DetailedOfferCard = (props: DetailedOfferCardProps) => {
  const { cmsData, productsData, userId } = props;
  const { account } = useAppContext();
  const { type: userBrand } = account || {};
  const [isLoading, setIsLoading] = useState(false);
  const productData = Object.values(productsData || [])[0]?.find((n) => n.id === cmsData?.ratorPackageId);
  const { offers } = OffersJson;

  const amountGB = getAmountGbNumber(productData?.dataAmount || 0);
  const isB2bPrice = productData?.brand === Brand.B2B;
  const dataEuAmount = getAmountGbNumber(productData?.dataEuAmount || 0);
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;
  const router = useRouter();
  const toast = useToastNotice();
  const { utmTracking, salesTracking } = useTracking();

  const isPrepaid = productData?.ratePlan === ProductRatePlan.NonContract;

  if (!cmsData) return null;

  const addProductToCart = async () => {
    try {
      setIsLoading(true);
      await addToCart({
        offer: cmsData,
        productData,
        brand: productData?.brand || Brand.B2C,
        userId,
        salesTracking,
        utmTracking,
      });

      router.push('/checkout');
    } catch (error) {
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
      setIsLoading(false);
    }
  };

  const hasOfferPrice = !!cmsData.offerPrice;
  const offerPrice = formatDisplayPrice(cmsData.offerPrice, isB2bPrice);
  const regularPrice = formatDisplayPrice(productData?.price?.amount, isB2bPrice, productData?.price?.amountExclVat);
  const priceValue = hasOfferPrice ? offerPrice : regularPrice;
  const priceText = isPrepaid ? offers.offerCards.prepaidPrice : offers.offerCards.price;
  const priceTextWithValues = getCmsTextWithValues(priceText, {
    offerPrice: priceValue,
  });

  return (
    <StyledCard data-testid="detailed-offer-card">
      <CardContainer>
        <TextWrapper>
          <Typography
            type={TypographyType.HeadingM}
            align="center"
            text={surfDataPretty(productData?.dataAmount || 0)}
          />
          <Price type={TypographyType.HeadingM} align="center" color={Color.Hallon1} text={priceTextWithValues} />
          {cmsData && cmsData.previousCampaignPrice.length > 0 && (
            <PreviousCampaignPriceWrapper>
              <PreviousCampaignPrice offer={cmsData} />
            </PreviousCampaignPriceWrapper>
          )}
          <DetailContainer>
            {cmsData.extraUsp && (
              <ExtraUspWrapper>
                <Usp>
                  <IconWrapper>
                    <SvgIcon icon="thumbs-up" color={Color.Hallon1} size="small" />
                  </IconWrapper>
                  <Typography type={TypographyType.Detail} text={cmsData.extraUsp} />
                </Usp>
              </ExtraUspWrapper>
            )}

            {!isPrepaid && cmsData.offerPrice && (
              <FinePrintWrapper>
                <IconWrapper>
                  <SvgIcon icon="calendar" color={Color.Hallon1} size="small" />
                </IconWrapper>
                <OfferUspWrapper>
                  <Typography
                    type={TypographyType.Detail}
                    text={
                      <HtmlFromCMS
                        html={getCmsTextWithValues(offers.offerCards.offerDuration, {
                          offerDuration: cmsData.offerDuration || '',
                        })}
                        richText
                      />
                    }
                  />
                  {productData && (
                    <Typography
                      type={TypographyType.Detail}
                      text={getCmsTextWithValues(offers.offerCards.prepaidOffer, {
                        originalPrice: formatDisplayPrice(
                          productData?.price?.amount,
                          isB2bPrice,
                          productData?.price?.amountExclVat
                        ),
                      })}
                    />
                  )}
                </OfferUspWrapper>
              </FinePrintWrapper>
            )}

            {isPrepaid && (
              <>
                <FinePrintWrapper>
                  <FinePrint>
                    <IconWrapper>
                      <SvgIcon icon="calendar" color={Color.Hallon1} size="small" />
                    </IconWrapper>
                    <Typography type={TypographyType.Detail} text={offers.offerCards.prepaidDuration} />
                  </FinePrint>
                </FinePrintWrapper>

                {cmsData?.ratorPackageId === productData?.id && (
                  <FinePrintWrapper>
                    <FinePrint>
                      {cmsData?.offerPrice && (
                        <>
                          <IconWrapper>
                            <SvgIcon icon="limitcosts" color={Color.Hallon1} size="small" />
                          </IconWrapper>
                          <Typography
                            type={TypographyType.Detail}
                            text={getCmsTextWithValues(offers.offerCards.prepaidOffer, {
                              originalPrice: formatDisplayPrice(
                                productData?.price?.amount,
                                isB2bPrice,
                                productData?.price?.amountExclVat
                              ),
                            })}
                          />
                        </>
                      )}
                    </FinePrint>
                  </FinePrintWrapper>
                )}
              </>
            )}
            <RenderDataEuAmount dataEuAmount={dataEuAmount} />
          </DetailContainer>
        </TextWrapper>
        <ButtonWrapper>
          <Button
            onClick={(e: SyntheticEvent) => {
              e.preventDefault();
              addProductToCart();
            }}
            isLoading={isLoading}
            label={offers.offerCards.buttonText}
            data-testid="detailed-offer-card-add-to-cart-button"
          />

          {!isPrepaid && (
            <BottomUspText type={TypographyType.Detail} text={offers.offerCards.bottomUspText} align="center" />
          )}
        </ButtonWrapper>

        <DataUspList>
          <DataUspListItem $flexStyle="column">
            <DataUspHeading
              type={TypographyType.Detail}
              fontWeight={FontWeight.SemiBold}
              text={offers.offerCards.bodyTextBlack}
            />
            <DataUspSubheading
              type={TypographyType.Detail}
              text={getCmsTextWithValues(offers.offerCards.bodyTextRegular, {
                dataAmount: surfDataPretty(productData?.dataAmount || 0),
              })}
            />
          </DataUspListItem>
          {renderCorrectDataAmount(cmsData, amountGB, productsData)}
        </DataUspList>
      </CardContainer>
    </StyledCard>
  );
};

export default DetailedOfferCard;
