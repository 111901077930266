import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

export const ContentWrapper = styled.div`
  isolation: isolate;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: min-content min-content;
  grid-row-gap: ${spacing.x4};
  grid-column-gap: calc(${spacing.x1} * 10);
  align-items: center;
  align-content: center;
  margin: 0 auto;
  padding-top: ${spacing.x7};
  padding-bottom: ${spacing.x7};

  ${above.md`
    padding-top: ${spacing.x9};
    padding-bottom: ${spacing.x9};
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
  `}
`;
