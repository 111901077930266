import { Container, TabButton, TabLink } from '@/newComponents/GroupedButtons/GroupedButtons.styled';
import Typography from '@/newComponents/Typography/Typography';

import { Color } from '@/global/style/variables';
import { createRef } from 'react';
import { FontWeight } from '@/constants';

type ButtonPropLink = {
  as: 'a';
  link: string;
};

export type ButtonProp = { section: string; name: string; subLabel?: string; as?: 'button' | 'a'; link?: string };

export type ButtonPropExtended = ButtonProp | (ButtonProp & ButtonPropLink);

export type GroupedButtonsProps = {
  className?: string;
  buttonProps: ButtonPropExtended[];
  onClick: (view: Omit<ButtonProp, 'as'>) => void;
  selectedTab: string;
  ariaLabel?: string;
};

const handleKeyUp = (
  buttonProps: ButtonPropExtended[],
  onClick: (view: ButtonProp) => void,
  buttonRefs: React.RefObject<HTMLButtonElement>[]
) => {
  return (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    let nextIndex = null;
    if (event.key === 'Enter' || event.key === ' ') {
      nextIndex = index;
    } else if (event.key === 'ArrowLeft') {
      nextIndex = index > 0 ? index - 1 : buttonProps.length - 1;
    } else if (event.key === 'ArrowRight') {
      nextIndex = index < buttonProps.length - 1 ? index + 1 : 0;
    }
    if (nextIndex !== null && buttonProps[nextIndex]) {
      const button = buttonProps[nextIndex];
      onClick({ section: button.section, name: button.name });
      const buttonElement = buttonRefs[nextIndex].current;
      if (buttonElement) buttonElement.focus();
    }
  };
};

const GroupedButtons = (props: GroupedButtonsProps) => {
  const { className, buttonProps, onClick, selectedTab, ariaLabel } = props;
  const buttonRefs = buttonProps.map(() => createRef<HTMLButtonElement>());

  const onKeyUp = handleKeyUp(buttonProps, onClick, buttonRefs);

  const shouldShowLeftBorder = (index: number) => {
    if (index === 0) return true;
    return buttonProps[index - 1].section !== selectedTab;
  };

  const renderButton = (button: ButtonPropExtended, index: number) => {
    const showLeftBorder = shouldShowLeftBorder(index);
    const showRightBorder = index === buttonProps.length - 1;
    const isSelected = button.section === selectedTab;
    const tabItemProps = {
      onClick: () => onClick({ section: button.section, name: button.name }),
      'data-testid': `tab-button-${button.section}`,
      value: button.section,
      $active: isSelected,
      $leftBorder: showLeftBorder,
      $rightBorder: showRightBorder,
      role: 'tab',
      'aria-selected': isSelected,
      'aria-controls': button.section,
      id: `${button.section}-tab`,
      tabIndex: isSelected ? 0 : -1,
      onKeyUp: (e: React.KeyboardEvent<HTMLButtonElement>) => onKeyUp(e, index),
      ref: buttonRefs[index],
    };

    const tabItemContent = (
      <>
        <Typography text={button.name} align="center" fontWeight={FontWeight.SemiBold} />
        {button.subLabel && (
          <Typography text={button.subLabel} align="center" color={Color.Hallon1} fontWeight={FontWeight.Medium} />
        )}
      </>
    );

    if (button.as === 'a' && button.link) {
      return (
        <TabLink shallow replace scroll={false} linkHref={button.link} key={button.section} {...tabItemProps}>
          {tabItemContent}
        </TabLink>
      );
    }

    return (
      <TabButton key={button.section} {...tabItemProps}>
        {tabItemContent}
      </TabButton>
    );
  };

  return (
    <Container
      id="grouped-buttons"
      className={className}
      $count={buttonProps.length}
      role="tablist"
      aria-label={ariaLabel}
    >
      {buttonProps.map(renderButton)}
    </Container>
  );
};

export default GroupedButtons;
