import { StickyUspBlock } from '@/types';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { FontWeight } from '@/constants';

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useHeaderRef } from '@/global/context/HeaderRefContext';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import styles from './StickyUspBanner.module.css';
import { getTheme } from './StickyUspBanner.utils';

type StickyUpsBannerProps = {
  data: StickyUspBlock;
  firstBlock: boolean;
};

const StickyUpsBanner = (props: StickyUpsBannerProps) => {
  const { data, firstBlock } = props;
  const { $type, header, sticky, theme, usps } = data;

  const { $values: uspList } = usps;
  const [isOverlapping, setIsOverlapping] = useState(false);
  const uspBannerRef = useRef<HTMLHeadElement>(null);
  const uspListRef = useRef<HTMLDivElement>(null);
  const { headerRef } = useHeaderRef();
  useTrackPromotionViewed(uspBannerRef, $type, header);

  const { backgroundColor, textColor } = getTheme(theme) ?? {};

  // Add shadow based on if the uspList is behind the header
  useEffect(() => {
    if (!sticky) return;

    const checkOverlap = () => {
      if (!uspBannerRef.current || !uspListRef.current || !headerRef.current) return;

      const headerElement = headerRef.current;

      const headerIsShown = window.getComputedStyle(headerElement).position === 'fixed';
      const mainHeaderHeight = headerElement?.getBoundingClientRect().height ?? 0;
      const secondaryHeaderHeight =
        headerElement?.parentElement?.nextElementSibling?.getBoundingClientRect().height ?? 0;

      const headerTotalHeight = mainHeaderHeight + secondaryHeaderHeight;
      const headerHeight = headerIsShown ? headerTotalHeight : 0;

      uspBannerRef.current.style.top = `${headerHeight}px`;

      const headerRect = uspBannerRef.current.getBoundingClientRect();
      const ulRect = uspListRef.current.getBoundingClientRect();

      if (ulRect.top < headerRect.bottom) {
        setIsOverlapping(true);
      } else {
        setIsOverlapping(false);
      }
    };

    checkOverlap();

    window.addEventListener('resize', checkOverlap);
    window.addEventListener('scroll', checkOverlap);

    return () => {
      window.removeEventListener('resize', checkOverlap);
      window.removeEventListener('scroll', checkOverlap);
    };
  }, [sticky, headerRef]);

  return (
    <>
      <header
        ref={uspBannerRef}
        className={classNames(styles.stickyHeaderContainer, {
          [styles.sticky]: sticky,
          [styles.shadow]: isOverlapping,
        })}
        style={{
          backgroundColor,
        }}
      >
        <Typography
          align="center"
          className={styles.stickyHeader}
          color={textColor}
          elementAs={firstBlock ? 'h1' : 'h2'}
          fontWeight={FontWeight.Medium}
          text={header}
          type={TypographyType.HeadingS}
        />
      </header>
      <div ref={uspListRef} className={styles.uspListContainer} style={{ backgroundColor }}>
        {uspList.length > 0 && (
          <ul className={styles.uspList}>
            {uspList.map((usp, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`${usp}-${index}`}>
                <Typography
                  className={styles.uspItem}
                  text={usp}
                  color={textColor}
                  type={TypographyType.Micro}
                  fontWeight={FontWeight.Medium}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default StickyUpsBanner;
