import styled, { css } from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { hexToRgba } from '@/global/utils/styleHelpers';
import { above } from '@/global/style/breakpoint';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  margin-bottom: ${spacing.x15};
  font-weight: ${FontWeight.SemiBold};

  ${above.sm`
    margin-bottom: ${spacing.x3};
  `}
`;

export const inputWrapperStyle = css<{
  $success?: boolean;
  $disabled?: boolean;
  $hasError?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  height: 48px;
  margin-top: ${spacing.x1};
  border-top: 1px solid ${Color.DarkGray2};
  border-left: 1px solid ${Color.DarkGray2};
  border-right: 1px solid ${Color.DarkGray2};
  border-top-left-radius: ${BorderRadius.Small};
  border-top-right-radius: ${BorderRadius.Small};
  border-bottom-width: 2px;
  overflow: hidden;
  border-style: solid;
  border-bottom-color: ${({ $success, $disabled, $hasError }) => {
    if ($success) {
      return `${Color.Forest}`;
    }
    if ($disabled) {
      return Color.DarkGray2;
    }
    if ($hasError) {
      return Color.Hallon1;
    }
    return `${Color.DarkGray1}`;
  }};
  transition: all 0.3s ease;
  &:focus-within {
    border-bottom: 2px solid ${Color.Ocean};
  }

  &:hover {
    > input {
      background-color: ${({ $disabled }) => ($disabled ? Color.White : Color.MineShaftHover)};
    }
    > div {
      background-color: ${({ $disabled }) => ($disabled ? Color.White : Color.MineShaftHover)};
    }
  }
`;

export const inputStyle = css<{ $disabled?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  border-top-left-radius: 4px;
  border: none;
  background-color: ${Color.White};
  font-weight: ${FontWeight.Regular};
  padding: ${spacing.x05} ${spacing.x2} 0 ${spacing.x2};
  line-height: 3;

  ::placeholder {
    color: ${({ $disabled }) => ($disabled ? Color.DarkGray2 : `${hexToRgba(Color.DarkGray1)}`)};
    opacity: 1;
    overflow: visible;
  }
  &:focus {
    outline: 0;
  }

  &:hover {
    ${({ $disabled }) => $disabled && `cursor: not-allowed`};
  }

  // Hides Input.Number arrows
  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputWrapper = styled.div<{
  $success?: boolean;
  $disabled?: boolean;
  $hasError?: boolean;
}>`
  ${inputWrapperStyle}

  input {
    ${inputStyle}
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const AddonsWrapper = styled.div`
  display: flex;
  top: 0;
  height: 100%;
  right: 0;
  background-color: ${Color.White};
`;

export const Addon = styled.div<{ $disabled?: boolean; $isWithoutLeftPadding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  border-top-right-radius: ${({ $isWithoutLeftPadding }) => ($isWithoutLeftPadding ? '4px' : 0)};
  overflow-wrap: anywhere;
  top: 0;
  height: 100%;
  right: 0;
  padding: ${({ $isWithoutLeftPadding }) =>
    $isWithoutLeftPadding ? `${spacing.x05} ${spacing.x2} 0 0` : `${spacing.x05} ${spacing.x15} 0 ${spacing.x15}`};
  color: ${({ $disabled }) => ($disabled ? Color.DarkGray2 : Color.DarkGray1)};
  font-weight: ${FontWeight.Regular};
  &:hover {
    ${({ $disabled }) => $disabled && `cursor: not-allowed`};
  }

  svg {
    position: relative;
    bottom: 2px;
  }
`;
