import { GtmLineItem } from '@/types';
import { RefObject, useEffect, useState } from 'react';

import { gtmViewItemList } from '@/global/gtm';
import { useIsInView } from '@/global/hooks/useIsInView';
import { getPercentageOfElementThatFitsInView } from '@/global/utils/elementCalculations';

export const useTrackListViewed = (
  ref: RefObject<HTMLElement>,
  listName?: string,
  offerItems?: GtmLineItem[] | null,
  watcher: string | null = null
) => {
  const viewPortPercentageOfElement = getPercentageOfElementThatFitsInView(ref);
  const threshold = Math.min(0.5, viewPortPercentageOfElement / 2);

  const isInView = useIsInView(ref, { threshold });
  const [hasBeenTracked, setHasBeenTracked] = useState(false);
  const [hasBeenInView, setHasBeenInView] = useState(false);

  useEffect(() => {
    if (!watcher) {
      return;
    }
    setHasBeenTracked(false);
  }, [watcher]);

  useEffect(() => {
    if (isInView) {
      setHasBeenInView(true);
    }
  }, [isInView]);

  useEffect(() => {
    if (hasBeenTracked || !hasBeenInView || !listName || !offerItems) {
      return;
    }
    gtmViewItemList(listName, offerItems || null);
    setHasBeenTracked(true);
  }, [listName, hasBeenInView, hasBeenTracked, offerItems]);
};
