'use client';

import HeaderWithIngress from '@/newComponents/CmsBlocks/Common/HeaderWithIngress/HeaderWithIngress';
import ResponsiveImage from '@/newComponents/CmsBlocks/Common/ResponsiveImage/ResponsiveImage';
import TextButtonWrapper from '@/newComponents/CmsBlocks/Common/TextButtonWrapper/TextButtonWrapper';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import type {
  ApiResponseError,
  BlockItemProps,
  CmsSettings,
  FullWidthRoundedImageTeaserBlock as FullWidthRoundedImageTeaserBlockType,
} from '@/types';
import { useRouter } from 'next/navigation';
import React, { SyntheticEvent, useRef, useState } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import { useGetProduct } from '@/global/hooks/useGetProduct';
import useToastNotice from '@/global/hooks/useToastNotice';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import { getBlockWithStickerCmsTheme } from '@/global/utils/Cms';

import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';
import { Background, StyledButton, StyledContentWrapper } from './FullWidthRoundedImageTeaserBlock.styled';

type FullWidthRoundedImageTeaserBlockProps = {
  data?: FullWidthRoundedImageTeaserBlockType;
  firstBlock?: boolean;
  imageHasPriority?: boolean;
} & BlockItemProps;

const FullWidthRoundedImageTeaserBlock = (props: FullWidthRoundedImageTeaserBlockProps) => {
  const { data, firstBlock = false, imageHasPriority = false } = props;
  const {
    $type,
    offerReference,
    buttonText,
    buttonType,
    link,
    image,
    mobileImage,
    textPosition,
    header,
    subheader,
    theme: themeKey,
    priceStickerHeader,
    priceStickerPreheader,
    priceStickerSubheader,
    priceStickerPreamble,
    infoStickerHeader,
    infoStickerPreamble1,
    infoStickerPreamble2,
    onlyButtonsAreLinked,
    bleed = false,
  } = data || {};
  const { url: buttonLinkUrl } = link || {};

  const { account } = useAppContext();
  const { type: userBrand } = account || {};

  const theme = getBlockWithStickerCmsTheme(themeKey);
  const { backgroundColor, foregroundColor, transparentSecondaryButton, textColorSecondaryButton } = theme || {};
  const secondaryButtonColor = buttonType === ButtonColor.Secondary ? textColorSecondaryButton : undefined;

  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const toast = useToastNotice();
  const ref = useRef(null);
  useTrackPromotionViewed(ref, $type, header);

  const productData = useGetProduct(offerReference?.ratorPackageId);
  const offerItem = offerReference && productData && generateGtmLineItemFromRaw(offerReference, productData);

  const { utmTracking, salesTracking } = useTracking();
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;

  const addProductToCart = async () => {
    if ($type && header) gtmSelectPromotion($type, header, offerItem || null);

    // If no productData is set, we want to route somewhere
    if (!offerReference || !productData) {
      if (buttonLinkUrl) {
        return router.push(buttonLinkUrl);
      }
      return;
    }

    try {
      setIsLoading(true);

      await addToCart({
        offer: offerReference,
        productData,
        brand: productData.brand,
        userId: props.userId,
        utmTracking,
        salesTracking,
      });

      router.push('/checkout');
    } catch (error) {
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
      setIsLoading(false);
    }
  };

  const handleHeroClick = (event: SyntheticEvent) => {
    if (!data || isLoading) return null;

    // if the user clicks the button, we'll end up here afterwards, stop things from that point on
    if (onlyButtonsAreLinked) {
      event.stopPropagation();
      return null;
    }

    addProductToCart();
  };

  const blockHasLinkOrOffer = Boolean(buttonLinkUrl || offerReference);

  return (
    <Background
      ref={ref}
      className={bleed ? 'banner-block' : ''}
      onClick={handleHeroClick}
      $backgroundIsClickable={!onlyButtonsAreLinked && blockHasLinkOrOffer}
      $backgroundColor={backgroundColor}
      $bleed={bleed}
    >
      <StyledContentWrapper $bleed={bleed} $backgroundColor={backgroundColor}>
        <ResponsiveImage
          textPosition={textPosition}
          imageProps={{
            src: image?.url,
            alt: image?.name ?? '',
            priority: imageHasPriority,
            seed: image?.id,
          }}
          mobileImageProps={
            mobileImage
              ? {
                  src: mobileImage.url,
                  alt: mobileImage.name,
                  priority: imageHasPriority,
                }
              : undefined
          }
          stickerPairProps={{
            priceStickerHeader: priceStickerHeader as string,
            priceStickerPreheader,
            priceStickerSubheader,
            priceStickerPreamble,
            infoStickerHeader,
            infoStickerPreamble1,
            infoStickerPreamble2,
            variant: theme?.stickerVariant,
          }}
        />
        <TextButtonWrapper textPosition={textPosition}>
          <HeaderWithIngress
            headerAsH1={firstBlock}
            leftAlignDesktop
            header={header || ''}
            textColor={foregroundColor}
            ingress={subheader}
          />
          {buttonText && blockHasLinkOrOffer && (
            <StyledButton
              color={buttonType ? ButtonColor[buttonType] : ButtonColor.Primary}
              size={ButtonSize.Small}
              onClick={addProductToCart}
              data-testid="fullwidth-teaser-block-button"
              isLoading={isLoading}
              label={buttonText}
              textColor={secondaryButtonColor}
              borderColor={secondaryButtonColor}
              transparentBackground={transparentSecondaryButton}
            />
          )}
        </TextButtonWrapper>
      </StyledContentWrapper>
    </Background>
  );
};

export default FullWidthRoundedImageTeaserBlock;
