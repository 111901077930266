'use client';

import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import type { BlockItemProps, DetailedOffers, GtmLineItem, MetaData } from '@/types';
import { useRef, useState } from 'react';

import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import useMatchMedia from '@/global/hooks/useMatchMedia';
import { useTrackListViewed } from '@/global/hooks/useTrackListViewed';
import { breakpoints } from '@/global/style/breakpoint';
import { getColorFromColorScheme } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';

import DetailedOfferCard from '@/components/Offers/DetailedOfferCard';
import DetailedOfferFilter from '@/components/Offers/DetailedOfferFilter';
import DetailedOfferFilterOfferList from '@/components/Offers/DetailedOfferFilterOfferList';

import {
  Container,
  ContentWrapper,
  HeaderWrapper,
  OffersWrapper,
  StyledCheckmarkList,
} from './DetailedOfferCardsBlock.styled';

type DetailedOffersBlockProps = {
  data: DetailedOffers;
  firstBlock?: boolean;
  metaData: MetaData;
} & BlockItemProps;

const DetailedOffersBlock = (props: DetailedOffersBlockProps) => {
  const { data, firstBlock = false, metaData, userId } = props;
  const ref = useRef<HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState(data.offerList?.offers[0]);
  const isMobile = useMatchMedia(`(max-width:${breakpoints.xs})`);
  const { permanentHeader } = data || {};

  const offerItems = (metaData?.products?.data || [])
    .map((product, index) => {
      const offer = data.offerList?.offers.find((offerItem) => offerItem.content.offer?.ratorPackageId === product.id)
        ?.content.offer;
      if (!offer) return null;
      return generateGtmLineItemFromRaw(offer, product, permanentHeader, index);
    })
    .filter(Boolean) as GtmLineItem[];

  useTrackListViewed(ref, permanentHeader, offerItems);

  if (!data.offerList) return null;
  const { offerList } = data;

  return (
    <Container ref={ref} $backgroundColor={data.campaignBackgroundColor} $hasHeader={!!data.campaignHeader}>
      <ContentWrapper>
        {data.campaignHeader && (
          <>
            <HeaderWrapper>
              <Typography
                align="center"
                type={firstBlock ? TypographyType.HeadingL : TypographyType.ExpressiveL}
                text={data.campaignHeader}
                color={getColorFromColorScheme(data.campaignTextColorScheme)?.header}
              />
            </HeaderWrapper>
            <StyledCheckmarkList colorScheme={data.campaignTextColorScheme} data={data.campaignUsps.$values} />
          </>
        )}

        <OffersWrapper>
          {offerList?.offers && !isMobile && (
            <DetailedOfferFilter
              cmsData={data}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              productsData={metaData.products}
            />
          )}

          {isMobile ? (
            <DetailedOfferFilterOfferList cmsData={data} productsData={metaData.products} />
          ) : (
            <DetailedOfferCard
              userId={userId}
              cmsData={selectedFilter?.content?.offer}
              productsData={metaData.products}
            />
          )}
        </OffersWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default DetailedOffersBlock;
