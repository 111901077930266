import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import LinkNext from '@/components/LinkNext/LinkNext';

export const Container = styled.nav<{ $count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $count }) => $count}, minmax(0, 1fr));
  margin-bottom: ${spacing.x2};

  ${above.sm`
    margin-bottom: ${spacing.x3};
  `};
`;

const TabItemStyles = css<{ $active: boolean; $leftBorder: boolean; $rightBorder: boolean }>`
  height: ${spacing.x8};
  padding: ${spacing.x1} ${spacing.x15};
  text-align: center;
  border: 1px solid ${Color.DarkGray2};
  font-weight: ${FontWeight.Regular};
  margin-bottom: 0;
  outline: 0;
  background-color: ${Color.White};

  ${({ $leftBorder }) =>
    !$leftBorder &&
    css`
      border-left: 0px;
    `};

  ${({ $rightBorder }) =>
    !$rightBorder &&
    css`
      border-right: 0px;
    `};

  &:first-of-type {
    border-top-left-radius: ${NewBorderRadius.Small};
    border-bottom-left-radius: ${NewBorderRadius.Small};
  }

  &:last-of-type {
    border-top-right-radius: ${NewBorderRadius.Small};
    border-bottom-right-radius: ${NewBorderRadius.Small};
  }

  &:hover {
    background-color: ${Color.MineShaftHover};
  }

  &:active {
    background-color: ${Color.MineShaftActive};
  }

  &:focus-visible {
    border: 2px solid ${Color.Ocean};
  }

  ${({ $active }) =>
    $active &&
    `
    border: 2px solid ${Color.Blue};
    font-weight: ${FontWeight.SemiBold};

    &:hover {
      background-color: ${Color.White};
    }

    &:focus-visible {
      border: 2px solid ${Color.Ocean};
    }
  `}
`;

export const TabButton = styled.button<{
  $active: boolean;
  $leftBorder: boolean;
  $rightBorder: boolean;
}>`
  ${TabItemStyles}
`;

export const TabLink = styled(LinkNext)<{
  $active: boolean;
  $leftBorder: boolean;
  $rightBorder: boolean;
}>`
  ${TabItemStyles};
  align-content: center;
  cursor: pointer;
  user-select: none;
`;
