import styled from 'styled-components';

import { spacing } from '@/global/style/variables';

export const FilterRadioContentContainer = styled.div`
  width: 100%;
`;

export const FilterRadioContentText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.x1};
`;

export const FilterRadioContentTextEnd = styled.div`
  margin-left: auto;
`;

export const FinePrint = styled.div`
  display: flex;
  justify-content: flex-end;
  > :first-child {
    margin-right: ${spacing.x05};
  }
`;
