import { Link } from '@/types';

import LinkNext from '@/components/LinkNext/LinkNext';

type BlockWrapperProps = {
  clickable: boolean;
  link?: Link;
  children: React.ReactNode;
  tabIndex?: number;
};

const BlockWrapper = (props: BlockWrapperProps) => {
  const { clickable, link, children, tabIndex = -1 } = props;

  if (clickable && link) {
    return (
      <LinkNext tabIndex={tabIndex} linkHref={link.url}>
        {children}
      </LinkNext>
    );
  }

  return <div style={{ width: '100%' }}>{children}</div>;
};

export default BlockWrapper;
