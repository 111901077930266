import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

export const Container = styled.section<{ $hasHeader: boolean; $backgroundColor?: string }>`
  flex: 1 1 100%;
  max-width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ $hasHeader }) =>
    $hasHeader === false &&
    css`
      margin-top: calc(-10 * ${spacing.x1});
      z-index: 1;
      ${above.sm`
          margin-top: -100px;
        `}
    `}

  &::before {
    ${({ $hasHeader }) =>
      $hasHeader &&
      css`
        content: '';
        width: 100%;
        height: 304px;
        position: absolute;
        z-index: -1;
        margin-top: -${spacing.x1};
        ${above.xs`
            margin-top: -${spacing.x4};
        `}
        ${above.sm`
            height: 240px;
            margin-top: -${spacing.x6};
        `}
      `}
    background-color: ${({ $backgroundColor }) => ($backgroundColor ? `#${$backgroundColor}` : '')};
  }
`;

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.x3} 0 ${spacing.x6} 0;
  margin: 0 ${spacing.x1};
  max-width: 522px;
  width: 100%;

  ${above.sm`
    padding: ${spacing.x4} ${spacing.x8} ${spacing.x8} ${spacing.x8};
    margin: 0;
  `}
  ${above.md`
    padding: ${spacing.x4} calc(3 * ${spacing.x6}) ${spacing.x8} calc(3 * ${spacing.x6});
  `}
`;

export const HeaderWrapper = styled.div`
  margin: 0 ${spacing.x2} ${spacing.x3} ${spacing.x2};
`;

export const OffersWrapper = styled.div`
  display: flex;
  padding: ${spacing.x2};
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1172px;
  gap: ${spacing.x2};

  ${above.sm`
    flex-wrap: nowrap;
    gap: 0;
  `}
`;

export const StyledCheckmarkList = styled(CheckmarkList)`
  && {
    margin: 0 ${spacing.x2} ${spacing.x4} ${spacing.x2};
    ${above.sm`
      margin: 0 0 ${spacing.x4} 0;
    `}
  }
`;
