import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { Offer, Product } from '@/types';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { surfDataPretty } from '@/global/utils/Calculate';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import { formatDisplayPrice } from '@/global/utils/Format';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import OffersJson from '@/pages/api/cms/offers.json';

import {
  FilterRadioContentContainer,
  FilterRadioContentText,
  FilterRadioContentTextEnd,
  FinePrint,
} from './FilterText.styled';

type FilterTextProps = {
  offer: Offer;
  productData?: Product;
  isPrepaid?: boolean;
};

const FilterText = (props: FilterTextProps) => {
  const { offer, productData, isPrepaid } = props;
  const { offers } = OffersJson;

  if (!productData) {
    return null;
  }

  const { offerPrice, offerDuration } = offer;
  const { brand, price, dataAmount } = productData;
  const { amount, amountExclVat } = price || {};
  const isB2bPrice = brand === 'B2B';

  const formattedOfferPrice = offerPrice
    ? `${formatDisplayPrice(offerPrice, isB2bPrice)}`
    : `${formatDisplayPrice(amount, isB2bPrice, amountExclVat)}`;

  const formattedOriginalPrice = `${formatDisplayPrice(amount, isB2bPrice, amountExclVat)}`;
  const priceText = getCmsTextWithValues(isPrepaid ? offers.offerCards.prepaidPrice : offers.offerCards.price, {
    offerPrice: formattedOfferPrice,
  });

  return (
    <FilterRadioContentContainer>
      <FilterRadioContentText>
        <Typography fontWeight={FontWeight.SemiBold} text={surfDataPretty(dataAmount)} />
        <FilterRadioContentTextEnd>
          <Typography fontWeight={FontWeight.SemiBold} color={Color.Hallon1} text={priceText} />
        </FilterRadioContentTextEnd>
      </FilterRadioContentText>
      {!isPrepaid && offerPrice && (
        <FinePrint>
          <Typography
            type={TypographyType.Detail}
            text={
              <HtmlFromCMS
                html={getCmsTextWithValues(offers.offerCards.offerDuration, {
                  offerDuration: offerDuration || '',
                })}
                richText
              />
            }
          />
          <Typography
            type={TypographyType.Detail}
            text={getCmsTextWithValues(offers.offerCards.fineprintLine2, {
              originalPrice: formattedOriginalPrice,
            })}
          />
        </FinePrint>
      )}
    </FilterRadioContentContainer>
  );
};

export default FilterText;
