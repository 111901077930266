import { Color } from '@/global/style/variables';
import { ToggleInfoHoverVariant } from '@/newComponents/ToggleInfo/ToggleInfo.styled';
import { LegalBlockTheme } from '@/types';

export const getTheme = (theme?: LegalBlockTheme) => {
  if (theme === LegalBlockTheme.White) {
    return {
      backgroundColor: Color.White,
      hoverVariant: ToggleInfoHoverVariant.MineShaft,
    };
  }
  if (theme === LegalBlockTheme.Gray) {
    return {
      backgroundColor: Color.Gray3,
      hoverVariant: ToggleInfoHoverVariant.MineShaft,
    };
  }

  return {
    backgroundColor: Color.Hallon6,
    hoverVariant: ToggleInfoHoverVariant.Hallon,
  };
};
