import Button from '@/newComponents/Button/Button';
import { ContentWrapper } from '@/newComponents/CmsBlocks/Common/ContentWrapper/ContentWrapper.styled';
import styled, { css } from 'styled-components';

import { above, below } from '@/global/style/breakpoint';
import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const Background = styled.section<{
  $backgroundImage?: string;
  $backgroundColor?: string;
  $backgroundIsClickable?: boolean;
  $bleed?: boolean;
}>`
  display: grid;

  cursor: ${({ $backgroundIsClickable }) => ($backgroundIsClickable ? 'pointer' : 'default')};

  width: 100%;

  ${({ $bleed, $backgroundColor }) =>
    $bleed
      ? css`
          background-color: ${$backgroundColor || Color.Transparent};
        `
      : css`
          ${GridLargeWrapper};
          border-radius: ${NewBorderRadius.Large};
          justify-self: center;

          margin-bottom: ${spacing.x7};
          margin-top: ${spacing.x7};

          ${above.md`
            margin-bottom: ${spacing.x9};
            margin-top: ${spacing.x9};
          `};
        `}
`;

export const StyledContentWrapper = styled(ContentWrapper)<{ $bleed: boolean; $backgroundColor?: string }>`
  ${below.md`
    grid-row-gap: ${spacing.x10};
  `}
  ${({ $bleed, $backgroundColor }) =>
    $bleed
      ? css`
          position: unset;
          ${GridLargeWrapper}
        `
      : css`
          background-color: ${$backgroundColor || Color.Transparent};
          border-radius: ${NewBorderRadius.Large};
          overflow: hidden;
        `};
`;

export const StyledButton = styled(Button)`
  margin-top: ${spacing.x3};
`;
