import Card from '@/newComponents/Card/Card';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';

export const StyledCard = styled(Card)`
  flex: 1 1 100%;
  max-width: 100%;

  ${above.sm`
    padding-left: ${spacing.x1};
    flex: 1 1 522px;
    max-width: 522px;
  `}
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
  width: 100%;
  flex-direction: column;

  ${above.sm`
    justify-content: flex-start;
  `}
`;

export const Price = styled(Typography)`
  margin-bottom: ${spacing.x1};
`;

export const DetailContainer = styled.div`
  margin-top: ${spacing.x2};
  ${above.sm`
    margin-top: ${spacing.x3};
  `}
`;

export const PreviousCampaignPriceWrapper = styled.div`
  margin-bottom: ${spacing.x2};
  ${above.sm`
    margin-bottom: ${spacing.x3};
  `}
`;

export const FinePrint = styled.div`
  display: inline-flex;
  align-items: center;

  a {
    margin-left: ${spacing.x05};
  }
`;

export const FinePrintWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.x15};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const OfferUspWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > :first-child {
    margin-right: ${spacing.x05};
  }
`;

export const ExtraUspWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Usp = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.x15};
`;

export const TextWrapper = styled.div`
  text-align: center;
  flex: 0 0 auto;
  margin-bottom: ${spacing.x3};
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  text-align: center;
  margin-bottom: ${spacing.x2};

  ${above.sm`
     margin-bottom: ${spacing.x3};
 `};
`;

export const BottomUspText = styled(Typography)`
  margin-top: ${spacing.x2};

  ${above.sm`
    margin-top: ${spacing.x3}
 `};
`;

export const DataUspList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
`;

export const DataUspListItem = styled.li<{ $flexStyle?: string }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${spacing.x05} 0;

  ${(props) =>
    props.$flexStyle === 'column' &&
    css`
      flex-direction: ${props.$flexStyle};
      align-items: flex-start;
    `};

  &:first-child::before {
    content: '';
    height: 1px;
    background: ${Color.Gray2};
    display: block;
    position: absolute;
    left: -${spacing.x25};
    top: 0;
    margin: auto;
    width: calc(100% + 40px);
    ${above.sm`
      left: -${spacing.x3};
      width: calc(100% + ${spacing.x6});
    `}
  }

  &:first-child {
    padding-top: ${spacing.x25};
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const DataUspListItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spacing.x1};
`;

export const StyledMediaIcon = styled(SvgIcon)`
  margin-right: ${spacing.x2};

  ${above.md`
    margin-right: ${spacing.x3};
  `}
`;

export const DataUspHeading = styled(Typography)`
  margin-bottom: ${spacing.x1};
`;

export const DataUspSubheading = styled(Typography)`
  margin-bottom: ${spacing.x1};
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing.x1};
`;
