import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import { Orientation } from '@/newComponents/CheckmarkList/CheckmarkList.types';
import OfferCard from '@/newComponents/OfferCard/OfferCard';

import { BlockListItem, DetailedOffers, OfferListItem, Product } from '@/types';
import { Fragment, useState } from 'react';

import { gtmSelectItemListItem } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import { getColorFromColorScheme } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';

import OffersJson from '@/pages/api/cms/offers.json';

import { FontWeight } from '@/constants';
import { BottomUspList, BottomUspListHeader, CardsWrapper, Title } from './DetailedOfferFilterOfferList.styled';

type DetailedOfferFilterOfferListProps = {
  cmsData: DetailedOffers;
  productsData: { data: Product[] };
};

const DetailedOfferFilterOfferList = (props: DetailedOfferFilterOfferListProps) => {
  const { cmsData, productsData } = props;
  const { permanentUsps, offerList, permanentBackgroundColor, permanentHeader, permanentTextColorScheme } = cmsData;
  const offers = offerList?.offers ?? [];
  const offerListName = offerList?.name;

  const { userId } = useAppContext();

  const [selectedOffer, setSelectedOffer] = useState(offers[0]?.content?.offer?.ratorPackageId);

  const usps = permanentUsps;
  const regularSurfInfoExists = offers.some((offer) => offer.content.offer?.regularSurfAmount);

  const clickHandler = (ratorPackageId: string) => {
    setSelectedOffer(ratorPackageId);
  };

  return (
    <CardsWrapper $hasBottomUspList={usps?.$values.length > 0}>
      <Title text={OffersJson.offers.offerCards.chooseSurfAmount} align="center" fontWeight={FontWeight.SemiBold} />
      {offers?.map((item: BlockListItem<OfferListItem>) => {
        const { content } = item;
        const { offer } = content;
        return (
          <Fragment key={offer?.ratorPackageId}>
            <OfferCard
              userId={userId}
              offerCallout={content.offerCallout}
              data={content.offer}
              highlight={content.highlight}
              type={offerListName}
              itemListName={offerListName}
              selectedFilter={offerListName}
              regularSurfInfoExists={regularSurfInfoExists}
              productsData={productsData}
              selected={offer?.ratorPackageId === selectedOffer}
              mobileBrowserClickHandler={(ratorPackageId: string) => {
                const productData = productsData.data.find((product: Product) => product.id === ratorPackageId);
                if (offer && productData) {
                  const gtmListItem = generateGtmLineItemFromRaw(offer, productData, offerListName);
                  gtmSelectItemListItem(gtmListItem);
                }
                clickHandler(ratorPackageId);
              }}
            />
          </Fragment>
        );
      })}
      {usps?.$values.length > 0 && (
        <BottomUspList $backgroundColor={permanentBackgroundColor}>
          {permanentHeader && (
            <BottomUspListHeader
              color={getColorFromColorScheme(permanentTextColorScheme)?.header}
              text={permanentHeader}
              fontWeight={FontWeight.SemiBold}
            />
          )}
          <CheckmarkList
            data={usps?.$values}
            colorScheme={permanentTextColorScheme}
            orientation={Orientation.Horizontal}
          />
        </BottomUspList>
      )}
    </CardsWrapper>
  );
};

export default DetailedOfferFilterOfferList;
