import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';

export const CountdownHeroBlockWrapper = styled.section<{ $backgroundColor: string; $pointer: boolean }>`
  display: grid;
  width: 100%;
  background-color: ${({ $backgroundColor }) => `${$backgroundColor}`};
  cursor: ${({ $pointer }) => ($pointer ? 'pointer' : 'default')};
`;

export const CountdownContainer = styled.div`
  display: grid;
  justify-content: center;

  ${above.md`
    justify-content: end;
  `}
`;
