import { Color } from '@/global/style/variables';
import { Product } from '@/types';
import { checkHasUnlimitedData, surfDataPretty } from '@/global/utils/Calculate';
import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';
import { getCmsTextWithValues } from '@/global/utils/Cms';
import Button from '@/newComponents/Button/Button';
import OffersJson from '@/pages/api/cms/offers.json';
import { FontWeight } from '@/constants';
import styles from './OfferCardSticky.module.css';

type OfferCardStickyProps = {
  productData?: Product;
  addProductToCart: () => void;
  isLoading: boolean;
  offersLabels: typeof OffersJson['offers'];
  regularSurfAmount: string;
  formattedPriceText: string;
  offerDuration?: string;
  regularPrice: string;
};

const OfferCardSticky = (props: OfferCardStickyProps) => {
  const {
    productData,
    addProductToCart,
    isLoading,
    offersLabels,
    regularSurfAmount,
    formattedPriceText,
    offerDuration,
    regularPrice,
  } = props;

  const isUnlimited = checkHasUnlimitedData(productData?.dataAmount ?? 0);
  const dataLabel = isUnlimited ? offersLabels.offerCards.unlimited : surfDataPretty(productData?.dataAmount ?? 0);

  return (
    <div className={styles.stickyOffer}>
      <div className={styles.stickySurf}>
        <SvgIcon icon="mobile" size="small" />
        <Typography text={dataLabel} type={TypographyType.Detail} />
        {regularSurfAmount && (
          <Typography
            text={`${regularSurfAmount} GB`}
            type={TypographyType.Detail}
            fontWeight={FontWeight.Regular}
            strikethrough
          />
        )}
      </div>
      <Divider color={Color.Gray2} />
      <div className={styles.stickyPrice}>
        <div>
          <Typography type={TypographyType.HeadingS} text={formattedPriceText} color={Color.Hallon1} />
          <Typography
            type={TypographyType.Detail}
            text={
              <HtmlFromCMS
                richText
                html={getCmsTextWithValues(offersLabels.offerCards.offerDuration, {
                  offerDuration: offerDuration ?? '',
                }).concat(
                  getCmsTextWithValues(offersLabels.offerCards.fineprintLine2, {
                    originalPrice: regularPrice,
                  })
                )}
              />
            }
          />
        </div>
        <Button
          data-testid="sticky-offer-button"
          label={offersLabels.offerCards.buttonText}
          onClick={addProductToCart}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default OfferCardSticky;
