import { ReactNode } from 'react';

import { IconWithLabelContainer } from './IconWithLabel.styled';

type IconWithLabelProps = {
  className?: string;
  icon: ReactNode;
  label: ReactNode;
};

const IconWithLabel = (props: IconWithLabelProps) => {
  const { icon, label, className } = props;
  return (
    <IconWithLabelContainer className={className}>
      {icon}
      {label}
    </IconWithLabelContainer>
  );
};

export default IconWithLabel;
