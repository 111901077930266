'use client';

import HeaderWithIngress from '@/newComponents/CmsBlocks/Common/HeaderWithIngress/HeaderWithIngress';
import { type HeadlineBlock as HeadlineBlockType } from '@/types';

import { Color } from '@/global/style/variables';

import { HeadlineBlockWrapper, IngressWrapper } from './HeadlineBlock.styled';

export type HeadlineBlockProps = {
  data?: HeadlineBlockType | null;
  firstBlock?: boolean;
};

const HeadlineBlock = (props: HeadlineBlockProps) => {
  const { data, firstBlock = false } = props;
  return (
    <HeadlineBlockWrapper $theme={data?.theme}>
      <IngressWrapper>
        <HeaderWithIngress
          header={data?.header || ''}
          headerAsH1={firstBlock}
          textColor={Color.MineShaft}
          ingress={data?.preamble}
        />
      </IngressWrapper>
    </HeadlineBlockWrapper>
  );
};

export default HeadlineBlock;
