import { TypographyType } from '@/newComponents/Typography/Typography.types';
import classNames from 'classnames';

import { Color } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import { BadgeProps } from './Badge.types';
import styles from './Badge.module.css';
import Typography from '../Typography/Typography';

const Badge = (props: BadgeProps) => {
  const {
    text,
    className,
    withMargin = true,
    slim = false,
    colorVariant = Color.MineShaft,
    'data-testid': dataTestId,
  } = props;

  const variantMap = {
    [Color.MineShaft]: styles.badgeMineShaft,
    [Color.Rose]: styles.badgeRose,
    [Color.Lemon3]: styles.badgeLemon3,
    [Color.Blue]: styles.badgeBlue,
    [Color.Forest]: styles.badgeForest,
    [Color.Gray2]: styles.badgeGray,
    [Color.Transparent]: '',
  };

  const classes = classNames(styles.badgeContainer, variantMap[colorVariant], className, {
    [styles.badgeContainerMargin]: withMargin,
  });

  return (
    <div className={classes} data-testid={dataTestId}>
      <Typography
        className={classNames(styles.badgeText, { [styles.badgeTextSlim]: slim })}
        text={text}
        type={slim ? TypographyType.Detail : TypographyType.Body}
        fontWeight={slim ? FontWeight.Regular : FontWeight.Medium}
        align="center"
      />
    </div>
  );
};

export default Badge;
