// Avoid using this component for new development. Use components found under @/newComponents/Radio
import { useId } from 'react';

import { Color } from '@/global/style/variables';

import {
  CircleSpan,
  HiddenRadiobutton,
  Label,
  OuterWrapper,
  RadiobuttonContainer,
  StyledBadge,
  StyledRadiobutton,
  Text,
  Wrapper,
} from './Radiobutton.styled';

type RadioButtonProps = {
  className?: string;
  name?: string;
  label?: string;
  fullWidth?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  value?: string;
  state?: boolean;
  offerCallout?: string;
  setState: () => void;
  register?: () => void;
  disabled?: boolean;
  radioButtonSubRow?: React.ReactNode;
  radioButtonText?: React.ReactNode;
  dataTestId?: string;
};

const Radiobutton = (props: RadioButtonProps) => {
  const {
    className = '',
    children,
    name,
    label,
    value,
    fullWidth,
    hidden,
    state,
    offerCallout,
    setState,
    register,
    disabled = false,
    radioButtonSubRow,
    radioButtonText,
    dataTestId,
  } = props;

  const uniqueId = useId();

  return (
    <Label data-testid={dataTestId} className={`${className} ${state ? 'checked' : ''}`} $disabled={disabled}>
      <OuterWrapper $fullWidth={fullWidth}>
        <Wrapper>
          <RadiobuttonContainer>
            <HiddenRadiobutton
              id={uniqueId}
              defaultChecked={state}
              onClick={setState}
              type="radio"
              name={name}
              value={value}
              ref={register}
              disabled={disabled}
            />
            <StyledRadiobutton hidden={hidden} $disabled={disabled} className={state ? 'checked' : ''}>
              <CircleSpan />
            </StyledRadiobutton>
          </RadiobuttonContainer>
          {radioButtonText || <Text>{children || label}</Text>}
          {radioButtonSubRow}
        </Wrapper>
        {offerCallout && (
          <StyledBadge text={offerCallout} data-testid={`${dataTestId}-callout`} colorVariant={Color.Rose} />
        )}
      </OuterWrapper>
    </Label>
  );
};

export default Radiobutton;
