import Button from '@/newComponents/Button/Button';
import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { NewBorderRadius, spacing } from '@/global/style/variables';

export const ContentWrapper = styled.div<{ $backgroundColor: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
  border-radius: ${NewBorderRadius.Medium};
  overflow: hidden;
  display: grid;
  grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, 1fr);
  height: 100%;
  padding: ${spacing.x2};

  ${above.xs`
    padding: ${spacing.x3};
  `}

  ${above.md`
    padding: ${spacing.x4};
  `}

  ${above.lg`
    padding: ${spacing.x5};
  `}
`;

export const Content = styled.div`
  display: grid;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 172px;
  height: 172px;

  margin: ${spacing.x2} auto;
  border-radius: ${NewBorderRadius.Medium};
  overflow: hidden;

  ${above.md`
    width: 350px;
    height: 350px;
  `}
`;

export const Picture = styled.picture`
  max-width: 250px;
  margin: auto;
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
`;
