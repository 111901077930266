'use client';

import Button from '@/newComponents/Button/Button';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import type { UspBlock as UspBlockType } from '@/types';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';

import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import { spacing } from '@/global/style/variables';
import { getUspCmsTheme } from '@/global/utils/Cms';

import { Container, ContentWrapper, IconList, IconListItem, UspIcon } from './UspBlock.styled';

type UspBlockProps = {
  data: UspBlockType;
};

const UspBlock = (props: UspBlockProps) => {
  const { data } = props;
  const { theme } = data;
  const { backgroundColor, foregroundColor } = getUspCmsTheme(theme) ?? {};
  const router = useRouter();
  const baseUrl = process.env.CMS_RETURN_URL;
  const ref = useRef<HTMLElement>(null);
  useTrackPromotionViewed(ref, data.$type, data.header);
  const renderIconList = () => {
    const shouldRender =
      data.usp1Image || data.usp1Text || data.usp2Image || data.usp2Text || data.usp3Image || data.usp3Text;
    if (!shouldRender) return null;

    return (
      <IconList>
        {data.usp1Image && (
          <IconListItem>
            <UspIcon
              src={`${baseUrl}${data.usp1Image?.url}?width=64&height=64&bgcolor=${backgroundColor}`}
              alt={data.usp1Image?.name}
              width="64px"
              height="64px"
            />
            <Typography text={data.usp1Text} align="center" type={TypographyType.ExpressiveL} color={foregroundColor} />
          </IconListItem>
        )}

        {data.usp2Image && (
          <IconListItem>
            <UspIcon
              src={`${baseUrl}${data.usp2Image?.url}?width=64&height=64&bgcolor=${backgroundColor}`}
              alt={data.usp2Image?.name}
              width="64px"
              height="64px"
            />
            <Typography text={data.usp2Text} align="center" type={TypographyType.ExpressiveL} color={foregroundColor} />
          </IconListItem>
        )}

        {data.usp3Image && (
          <IconListItem>
            <UspIcon
              src={`${baseUrl}${data.usp3Image?.url}?width=64&height=64&bgcolor=${backgroundColor}`}
              alt={data.usp3Image?.name}
              width="64px"
              height="64px"
            />
            <Typography text={data.usp3Text} align="center" type={TypographyType.ExpressiveL} color={foregroundColor} />
          </IconListItem>
        )}
      </IconList>
    );
  };

  const handleButtonClick = () => {
    const { buttonLink } = data;
    if (buttonLink?.url) {
      return router.push(buttonLink.url);
    }
  };

  return (
    <Container ref={ref} $backgroundColor={backgroundColor}>
      <ContentWrapper>
        {data.header && (
          <Typography align="center" text={data.header} type={TypographyType.HeadingM} color={foregroundColor} />
        )}
        <Divider marginYSm={spacing.x3} marginY={spacing.x25} />

        {renderIconList()}

        {data.buttonText && (
          <Button
            label={data.buttonText}
            color={data.buttonType}
            onClick={handleButtonClick}
            data-testid="hardware-teaser-block-button"
          />
        )}
      </ContentWrapper>
    </Container>
  );
};

export default UspBlock;
