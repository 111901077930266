'use client';

import { Device, GclidSessionStorageKey } from '@/constants';
import { type HeroBannerflowBlock, PageTrackingProps } from '@/types';
import { useEffect, useRef, useState } from 'react';

import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import useTracking, { UtmTracking } from '@/global/hooks/useTracking';
import useWindowWidth from '@/global/hooks/useWindowWidth';
import { breakpointsValues } from '@/global/style/breakpoint';

import {
  BannerflowWrapper,
  BannerflowHeader,
  BannerflowPreamble,
  Wrapper,
} from '@/components/Bannerflow/Bannerflow.styled';

type BannerFlowScriptProps = {
  advertisementId: string;
  destinationId: string;
  bannerflowContainerId: string;
  utmTracking: UtmTracking | null;
  gclid: string | null;
  salesTrackingProps: PageTrackingProps | null;
};

const getScriptSource = (bannerflowProps: BannerFlowScriptProps) => {
  const { advertisementId, destinationId, bannerflowContainerId, utmTracking, gclid, salesTrackingProps } =
    bannerflowProps;

  if (!advertisementId || !destinationId) return null;
  let scriptSrc = `https://bf-content.hallon.se/a/${advertisementId}?did=${destinationId}&targetwindow=_top&deeplink=on&responsive=on&preload=off&container=%23${bannerflowContainerId}&redirecturl=`;
  if (utmTracking) {
    scriptSrc += `&utm_medium=${utmTracking.utmMedium}&utm_source=${utmTracking.utmSource}`;
  }
  if (gclid) {
    scriptSrc += `&gclid=${gclid}`;
  }
  if (salesTrackingProps) {
    scriptSrc += `&salesAgent=${salesTrackingProps.salesAgent}&salesChannel=${salesTrackingProps.salesChannel}`;
  }

  return scriptSrc;
};

type BannerflowProps = {
  data: HeroBannerflowBlock;
  id: string;
  firstBlock: boolean;
};

const Bannerflow = (props: BannerflowProps) => {
  const { data, id, firstBlock } = props;
  const {
    $type,
    bannerflowDesktopA,
    bannerflowMobileA,
    bannerflowDid: destinationId,
    bGcolor,
    bannerflowHeader,
    bannerflowPreamble,
    desktopHeight,
    mobileHeight,
  } = data;

  // These need to be unique to support multiple banner flow blocks on the same page
  const bannerflowScriptId = `bannerflow-script-${id}`;
  const bannerflowContainerId = `bannerflow-container-${id}`;

  const ref = useRef<HTMLDivElement>(null);
  useTrackPromotionViewed(ref, $type, destinationId);
  const [currentDevice, setCurrentDevice] = useState<Device>();
  const advertisementId = currentDevice === Device.DESKTOP ? bannerflowDesktopA : bannerflowMobileA;

  const { utmTracking, salesTracking } = useTracking();
  const [windowWidth] = useWindowWidth();

  useEffect(() => {
    if (!windowWidth) return;

    const device = windowWidth <= breakpointsValues.sm ? Device.MOBILE : Device.DESKTOP;
    setCurrentDevice(device);
  }, [windowWidth]);

  useEffect(() => {
    if (currentDevice && advertisementId) {
      if (ref?.current) {
        // if we already have a bannerflow element, remove it. E.g when you change window size and go from mobile -> desktop breakpoint or vice verca a new instance will be created
        ref.current.querySelector(`#${bannerflowContainerId} div`)?.remove();
      }

      const heroCreative = document.createElement('script');
      const gclid = sessionStorage.getItem(GclidSessionStorageKey);

      const src = getScriptSource({
        advertisementId,
        destinationId,
        bannerflowContainerId,
        utmTracking,
        gclid,
        salesTrackingProps: salesTracking,
      });

      if (src) {
        heroCreative.src = src;
        heroCreative.id = bannerflowScriptId;
        const scriptTag = document.getElementById(bannerflowScriptId);
        if (scriptTag) {
          scriptTag.remove();
        }

        document.head.appendChild(heroCreative);
      }
    }

    // cleanup the script tag, always want to generate a new one when e.g routing
    return () => {
      const bannerflowScript = document.getElementById('bannerflow-script');
      if (bannerflowScript) {
        bannerflowScript.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDevice, advertisementId, destinationId, bannerflowContainerId, bannerflowScriptId]);

  return (
    <Wrapper ref={ref} $backgroundColor={bGcolor} className="bannerflow-block">
      <BannerflowWrapper
        $desktopHeight={desktopHeight || '576'}
        $mobileHeight={mobileHeight || '504'}
        id={bannerflowContainerId}
      >
        {bannerflowHeader && (
          <BannerflowHeader as={firstBlock ? 'h1' : 'h2'} $color={bGcolor}>
            {bannerflowHeader}
          </BannerflowHeader>
        )}
        {bannerflowPreamble && <BannerflowPreamble $color={bGcolor}>{bannerflowPreamble}</BannerflowPreamble>}
      </BannerflowWrapper>
    </Wrapper>
  );
};

export default Bannerflow;
