import { css } from 'styled-components';

const assetPrefix = process.env.BASE_URL;

const fonts = css`
  @font-face {
    font-family: 'BagossStandard';
    src: url('${assetPrefix}/static/fonts/BagossStandard-Regular.woff2') format('woff2'),
      url('${assetPrefix}/static/fonts/BagossStandard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'BagossStandard';
    src: url('${assetPrefix}/static/fonts/BagossStandard-Medium.woff2') format('woff2'),
      url('${assetPrefix}/static/fonts/BagossStandard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'BagossStandard';
    src: url('${assetPrefix}/static/fonts/BagossStandard-SemiBold.woff2') format('woff2'),
      url('${assetPrefix}/static/fonts/BagossStandard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;

export default fonts;
