import Typography from '@/newComponents/Typography/Typography';
import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const CardsWrapper = styled.ul<{ $alignCenter?: boolean; $hasBottomUspList?: boolean }>`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content;
  column-gap: ${spacing.x2};
  row-gap: ${spacing.x2};
  align-items: stretch;
  margin: 0;
  list-style-type: none;
  background-color: ${Color.White};
  padding: ${({ $hasBottomUspList }) =>
    $hasBottomUspList ? `${spacing.x15} ${spacing.x15} 0 ${spacing.x15}` : `${spacing.x15}`};
  border-radius: ${BorderRadius.Large};

  ${above.sm`
    position: relative;
    grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
    background-color: ${Color.Transparent};
    padding: 0;
    justify-content: ${({ $alignCenter }) => $alignCenter || ''};
  `}
`;

export const Title = styled(Typography)`
  margin: ${spacing.x2} 0;
`;

export const BottomUspList = styled.li<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => ($backgroundColor ? `#${$backgroundColor}` : `${Color.Hallon4}`)};
  position: relative;
  margin-left: -12px;
  width: calc(100% + ${spacing.x3});
  border-radius: 0 0 ${BorderRadius.Large} ${BorderRadius.Large};
  padding: ${spacing.x3};
  ${above.sm`
    width: calc(100% + ${spacing.x8});
    padding: ${spacing.x4} ${spacing.x4} ${spacing.x3} ${spacing.x4};
  `}
`;

export const BottomUspListHeader = styled(Typography)`
  margin-bottom: ${spacing.x3};
`;
