import ToggleInfo from '@/newComponents/ToggleInfo/ToggleInfo';
import styled from 'styled-components';

import { spacing } from '@/global/style/variables';

export const UspWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  margin: 0 auto;
`;

export const StyledToggleInfo = styled(ToggleInfo)`
  & > button {
    width: calc(100% + ${spacing.x6});
    margin-left: -${spacing.x3};
    margin-right: -${spacing.x3};
    padding: ${spacing.x2} ${spacing.x3} ${spacing.x3} ${spacing.x3};
  }
`;
