import Typography from '@/newComponents/Typography/Typography';

import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import ToggleInfo from '@/newComponents/ToggleInfo/ToggleInfo';
import { ToggleInfoHoverVariant } from '@/newComponents/ToggleInfo/ToggleInfo.styled';
import Divider from '@/newComponents/Divider/Divider';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import classNames from 'classnames';
import styles from './HardwareProductCampaign.module.css';

type HardwareProductCampaignProps = {
  backgroundColor?: Color;
  hoverVariant?: ToggleInfoHoverVariant;
  header?: string;
  body?: string;
  specialDealExpandedLabel?: string;
  specialDealCollapsedLabel?: string;
  specialDealCollapsedBody?: string;
  icon?: React.ReactNode;
};

const HardwareProductCampaign = (props: HardwareProductCampaignProps) => {
  const {
    backgroundColor = Color.Hallon6,
    hoverVariant = ToggleInfoHoverVariant.Hallon,
    header,
    body,
    specialDealExpandedLabel,
    specialDealCollapsedLabel,
    specialDealCollapsedBody,
    icon,
  } = props;

  return (
    <div
      className={classNames(styles.campaignContainer, { [styles.hasIcon]: Boolean(icon) })}
      style={{ backgroundColor }}
    >
      {icon}
      <div>
        {header && (
          <>
            <Typography
              text={header}
              type={TypographyType.ExpressiveL}
              color={Color.Hallon1}
              fontWeight={FontWeight.SemiBold}
            />
            <Divider marginY={spacing.x05} />
          </>
        )}
        {body && (
          <>
            <Typography text={<HtmlFromCMS html={body} />} />
            <Divider marginY={spacing.x05} />
          </>
        )}

        {specialDealCollapsedLabel && (
          <ToggleInfo
            ariaLabel={specialDealExpandedLabel}
            data-testid="hardware-product-campaign"
            header={<Typography text={specialDealCollapsedLabel} fontWeight={FontWeight.Medium} />}
            hoverVariant={hoverVariant}
          >
            <Typography text={<HtmlFromCMS html={specialDealCollapsedBody} />} />
          </ToggleInfo>
        )}
      </div>
    </div>
  );
};

export default HardwareProductCampaign;
