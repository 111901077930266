'use client';

import { MiniTeasersCmsTheme, TeaserBlock, MiniTeasersBlock } from '@/types';
import { gtmSelectPromotion } from '@/global/gtm';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import Typography from '@/newComponents/Typography/Typography';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import LazyLoadingImage from '@/newComponents/LazyLoadingImage/LazyLoadingImage';
import { NewBorderRadius } from '@/global/style/variables';
import styles from './MiniTeaser.module.css';

type MiniTeaserProps = {
  teaser: TeaserBlock;
  index: number;
  theme: MiniTeasersCmsTheme;
  data: MiniTeasersBlock;
  teaserBlockHeaders: string;
  imageHasPriority: boolean;
};

const MiniTeaser = (props: MiniTeaserProps) => {
  const { teaser, index, theme, data, teaserBlockHeaders, imageHasPriority } = props;

  const itemTheme = theme.items[index];
  const { backgroundColor, foregroundColor } = itemTheme;

  const linkInlineStyle = {
    '--mini-teaser-link-bg-color': backgroundColor,
    '--mini-teaser-link-text-color': foregroundColor,
  } as React.CSSProperties;

  return (
    <li className={styles.teaser} key={`teaser-item-${teaser.content?.header}-${index.toString()}`}>
      <a
        href={teaser.content?.link?.url || '#'}
        title={teaser.content?.link?.name}
        target={teaser.content?.link?.target ?? undefined}
        rel={teaser.content?.link?.target === '_blank' ? 'noopener' : undefined}
        className={styles.teaserLink}
        style={linkInlineStyle}
        onClick={() => {
          if (data.$type && teaser.content.header) {
            gtmSelectPromotion(data.$type, teaserBlockHeaders);
          }
        }}
      >
        {teaser.content?.image?.url && (
          <LazyLoadingImage
            src={`${process.env.CMS_RETURN_URL}/${teaser.content?.image?.url}?mode=crop&width=100&height=100`}
            alt={teaser.content?.image?.name}
            priority={imageHasPriority}
            seed={teaser.content?.image?.id}
            width={98}
            height={98}
            style={{
              borderRadius: NewBorderRadius.Medium,
            }}
          />
        )}

        <div className={styles.teaserTextWrapper}>
          {teaser.content?.preheader && (
            <Typography type={TypographyType.Detail} text={teaser.content?.preheader} color={foregroundColor} />
          )}
          <Typography type={TypographyType.ExpressiveL} text={teaser.content?.header} color={foregroundColor} />
        </div>
        <SvgIcon icon="chevron-right" size="small" color={itemTheme.foregroundColor} />
      </a>
    </li>
  );
};

export default MiniTeaser;
