import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { useEffect, useState } from 'react';
import ReactCountdown, { CountdownTimeDeltaFormatted } from 'react-countdown';

import { CountdownFraction, Wrapper } from './OfferCountdown.styled';

type OfferCountdownProps = {
  endDate: string;
};

const countdownFractions: ['days', 'hours', 'minutes', 'seconds'] = ['days', 'hours', 'minutes', 'seconds'];

const countdown = {
  days: 'Dagar',
  hours: 'Timmar',
  minutes: 'Minuter',
  seconds: 'Sekunder',
};

const OfferCountdown = ({ endDate }: OfferCountdownProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  const renderer = ({ formatted }: { formatted: CountdownTimeDeltaFormatted }) => {
    if (!countdown) return null;
    let timeLeftObject = formatted;
    // serverside the initial DateTime will diff by a second or two compared to the client. So start of with just - for the second and let that show after first mount
    if (!isMounted) timeLeftObject = { ...formatted, seconds: '-' };

    return (
      <Wrapper>
        {countdownFractions.map((fraction) => (
          <CountdownFraction key={fraction}>
            <Typography text={countdown[fraction]} type={TypographyType.Detail} align="center" />
            <Divider />
            <Typography text={timeLeftObject[fraction]} type={TypographyType.HeadingL} align="center" />
          </CountdownFraction>
        ))}
      </Wrapper>
    );
  };

  return <ReactCountdown date={Date.parse(endDate)} renderer={renderer} />;
};

export default OfferCountdown;
