import {
  ImageWrapper,
  StyledImage,
  StyledStickerPair,
} from '@/newComponents/CmsBlocks/Common/ResponsiveImage/ResponsiveImage.styled';
import { StickerPairProps } from '@/newComponents/Sticker/StickerPair';
import type { ImageProps } from 'next/image';
import React from 'react';

import useMatchMedia from '@/global/hooks/useMatchMedia';
import { breakpoints, ContentWidth } from '@/global/style/breakpoint';

type CustomImageProps = {
  src?: string;
  seed?: number;
} & Omit<ImageProps, 'src'>;

type ResponsiveImageProps = {
  className?: string;
  textPosition?: 'Left' | 'Right';
  squareImageOnly?: boolean;
  imageProps: CustomImageProps;
  mobileImageProps?: CustomImageProps;
  stickerPairProps?: {
    stickerOffsetTop?: string;
    stickerOffsetRight?: string;
    centered?: boolean;
  } & StickerPairProps;
};

const ResponsiveImage = (props: ResponsiveImageProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    imageProps: _imageProps,
    mobileImageProps,
    stickerPairProps,
    textPosition,
    squareImageOnly = false,
    className,
  } = props;
  const isMobile = useMatchMedia(`(max-width:${breakpoints.sm})`);

  const imageProps = isMobile && mobileImageProps ? mobileImageProps : _imageProps;
  const stickerOffsetTop = stickerPairProps?.stickerOffsetTop ?? '0px';
  const stickerOffsetRight = stickerPairProps?.stickerOffsetRight ?? '0px';
  const imageSrcWithBaseUrl = imageProps.src ? `${process.env.CMS_RETURN_URL || ''}${imageProps.src}` : undefined;

  return (
    <ImageWrapper className={className} $textPosition={textPosition} $squareImageOnly={squareImageOnly} ref={ref}>
      {imageSrcWithBaseUrl && (
        <StyledImage
          fill
          {...imageProps}
          src={imageSrcWithBaseUrl}
          sizes={`(max-width: 480px) 296px, (max-width: 768px) 320px, (max-width: 992px) 100vw, (max-width: 1148px) min(100vw, ${ContentWidth.textMax}), (max-width: 1400px) ${ContentWidth.textMax}, ${ContentWidth.textMax}`}
        />
      )}
      {stickerPairProps && (
        <StyledStickerPair
          isMobile={isMobile}
          {...stickerPairProps}
          $stickerOffsetTop={stickerOffsetTop}
          $stickerOffsetRight={stickerOffsetRight}
          $centered={Boolean(stickerPairProps?.centered)}
        />
      )}
    </ImageWrapper>
  );
};

export default ResponsiveImage;
