import styled from 'styled-components';

import { ContentWidth, GridMargins, above } from '@/global/style/breakpoint';
import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { spacing } from '@/global/style/variables';

export const BlockListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  section {
    grid-column: span 2;
  }

  section.half-block {
    min-height: 462px;
  }

  section:not(.half-block):not(.banner-block):not(.bannerflow-block) > *:first-child {
    width: 100%;
    padding-left: ${GridMargins.xxs};
    padding-right: ${GridMargins.xxs};

    ${above.sm`
      ${GridLargeWrapper};
    `}
  }

  section.bannerflow-block > *:first-child {
    ${above.sm`
      ${GridLargeWrapper};
    `}
  }

  ${above.md`
      section.half-block {
        grid-column: span 1;
        min-height: 580px;
      }

      section.half-block > *:first-child {
        display: block;
        width: 100%;
        max-width: calc((${ContentWidth.lg} - ${spacing.x3}) / 2);
      }

      section.half-block.ml-auto > *:first-child {
        margin-left: auto;
        margin-right: unset;
      }

      section.half-block.mr-auto > *:first-child {
        margin-right: auto;
        margin-left: unset;
      }
  `}
`;
