'use client';

import BlockWrapper from '@/newComponents/CmsBlocks/Common/BlockWrapper/BlockWrapper';
import { HalfWidthContainer } from '@/newComponents/CmsBlocks/Common/HalfWidthContainer/HalfWidthContainer.styled';
import HeaderWithIngress from '@/newComponents/CmsBlocks/Common/HeaderWithIngress/HeaderWithIngress';
import ResponsiveImage from '@/newComponents/CmsBlocks/Common/ResponsiveImage/ResponsiveImage';
import Divider from '@/newComponents/Divider/Divider';
import { HalfWidthImageBlock as HalfWidthImageBlockType } from '@/types/cms/startPageCmsData';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import { spacing } from '@/global/style/variables';
import { getBlockWithStickerCmsTheme } from '@/global/utils/Cms';

import { Content, ContentWrapper, StyledButton } from './HalfWidthImageBlock.styled';

type HalfWidthImageBlockProps = {
  data: HalfWidthImageBlockType;
  className?: string;
};

const HalfWidthImageBlock = (props: HalfWidthImageBlockProps) => {
  const { data, className } = props;
  const {
    $type,
    image,
    header,
    subheader,
    link,
    theme: themeKey,
    priceStickerHeader,
    priceStickerPreheader,
    priceStickerSubheader,
    priceStickerPreamble,
    infoStickerHeader,
    infoStickerPreamble1,
    infoStickerPreamble2,
  } = data;
  const ref = useRef<HTMLElement>(null);
  const router = useRouter();

  useTrackPromotionViewed(ref, $type, header);

  const handleButtonClick = () => {
    if (link?.url) {
      if ($type && header) gtmSelectPromotion($type, header);

      return router.push(link.url);
    }
  };

  const wrapperClickable = !data.onlyTheButtonIsLinked && data.link?.url;
  const theme = getBlockWithStickerCmsTheme(themeKey);
  const { backgroundColor } = theme ?? {};

  const blockHasLink = data.link?.url;

  return (
    <HalfWidthContainer className={className} ref={ref}>
      <BlockWrapper clickable={wrapperClickable} link={link}>
        <ContentWrapper $backgroundColor={backgroundColor}>
          {data.image?.url && (
            <>
              <ResponsiveImage
                imageProps={{
                  src: image?.url ? `${image?.url}?mode=Crop&width=300&height=300` : undefined,
                  alt: data.image?.name,
                  seed: data.image?.id,
                }}
                squareImageOnly
                stickerPairProps={{
                  priceStickerHeader,
                  priceStickerPreheader,
                  priceStickerSubheader,
                  priceStickerPreamble,
                  infoStickerHeader,
                  infoStickerPreamble1,
                  infoStickerPreamble2,
                  variant: theme?.stickerVariant,
                  stickerOffsetTop: `${spacing.x7}`,
                  stickerOffsetRight: `${spacing.x4}`,
                  centered: Boolean(infoStickerHeader),
                }}
              />
              {(priceStickerHeader || infoStickerHeader) && <Divider marginY={spacing.x8} marginYSm={spacing.x9} />}
            </>
          )}

          <Divider marginY={spacing.x2} />

          <Content>
            <HeaderWithIngress header={header} ingress={subheader} textColor={theme.foregroundColor} />
            <Divider />

            {data.buttonText && blockHasLink && (
              <StyledButton
                label={data.buttonText}
                color={data.buttonType}
                onClick={handleButtonClick}
                centered
                data-testid="hardware-teaser-block-button"
              />
            )}
          </Content>
        </ContentWrapper>
      </BlockWrapper>
    </HalfWidthContainer>
  );
};

export default HalfWidthImageBlock;
