'use client';

import Button from '@/newComponents/Button/Button';
import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';

import ResponsiveImage from '@/newComponents/CmsBlocks/Common/ResponsiveImage/ResponsiveImage';
import classnames from 'classnames';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { RichTextBlock as RichTextBlockType } from '@/types/cms/startPageCmsData';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import React, { useRef, useState } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import { getCmsTextWithValues, getNeutralCmsTheme } from '@/global/utils/Cms';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import AccordionChevron from '@/components/Accordion/AccordionChevron';
import styles from './RichTextBlock.module.css';

import TextButtonWrapper from '../Common/TextButtonWrapper/TextButtonWrapper';

type RichTextBlockProps = {
  data: RichTextBlockType;
  firstBlock?: boolean;
  imageHasPriority?: boolean;
};

const RichTextBlock = (props: RichTextBlockProps) => {
  const { data, firstBlock, imageHasPriority = false } = props;
  const {
    $type,
    theme,
    header,
    textPosition,
    mobileImagePosition,
    body,
    button,
    image,
    mobileImage,
    collapsible,
    collapsibleHeader,
    singleColumn,
    buttonType = ButtonColor.Primary,
  } = data;

  const { backgroundColor, foregroundColor, linkColor, transparentSecondaryButton } = getNeutralCmsTheme(theme);
  const { name, url } = button || {};
  const collapsableBlock = collapsible && collapsibleHeader;
  const [showContent, setShowContent] = useState(!collapsableBlock);

  const ref = useRef<HTMLElement>(null);
  useTrackPromotionViewed(ref, $type, header);
  const router = useRouter();

  const handleButtonClick = () => {
    if (!url) return null;

    if ($type && header) gtmSelectPromotion($type, header);
    return router.push(url);
  };

  const renderRichTextImage = () => {
    return (
      <div
        className={classnames(styles.imageWrapper, {
          [styles.imageWrapperLeft]: textPosition === 'Left',
          [styles.imageWrapperRight]: textPosition !== 'Left',
          [styles.imageWrapperMobileBottom]: mobileImagePosition === 'bottom',
        })}
      >
        <ResponsiveImage
          textPosition={textPosition}
          imageProps={{
            src: image?.url,
            alt: image?.name ?? '',
            priority: imageHasPriority,
            seed: image?.id,
          }}
          mobileImageProps={
            mobileImage
              ? {
                  src: mobileImage.url,
                  alt: mobileImage.name,
                  priority: imageHasPriority,
                }
              : undefined
          }
        />
      </div>
    );
  };

  const renderTextContent = () => {
    return (
      <TextButtonWrapper textPosition={textPosition} alignSelf={collapsible ? 'start' : 'center'}>
        <div className={styles.styledHeaderWithIngress}>
          <Typography
            text={header}
            type={collapsableBlock ? TypographyType.ExpressiveL : TypographyType.HeadingM}
            elementAs={firstBlock ? 'h1' : 'h2'}
            color={foregroundColor}
          />
          <HtmlFromCMS richText html={getCmsTextWithValues(body)} linkColor={linkColor} />
        </div>
        {url && name && (
          <Button
            color={ButtonColor[buttonType] ?? ButtonColor.Primary}
            transparentBackground={transparentSecondaryButton}
            size={ButtonSize.Small}
            onClick={handleButtonClick}
            data-testid="rich-text-block-button"
            label={name}
          />
        )}
      </TextButtonWrapper>
    );
  };

  return (
    <section className={styles.background} style={{ backgroundColor }}>
      <div className={styles.richTextWrapper}>
        {collapsableBlock && (
          <button
            className={styles.toggleHeader}
            onClick={() => setShowContent(!showContent)}
            aria-expanded={showContent}
            aria-controls="collapsible-content"
          >
            <Typography
              text={collapsibleHeader}
              type={TypographyType.HeadingM}
              elementAs={firstBlock ? 'h1' : 'h2'}
              color={foregroundColor}
            />
            <AccordionChevron orientation={showContent ? 'up' : 'down'} size="large" />
          </button>
        )}

        <AnimatePresence>
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: collapsible ? 0 : 'auto' }}
            animate={{ height: showContent ? 'auto' : 0 }}
            transition={{ duration: 0.3 }}
            exit={{ height: 0 }}
            key="rich-text-animated-content"
            id="collapsible-content"
          >
            <div
              className={classnames(styles.animatedRichTextBody, {
                [styles.singleColumnBody]: singleColumn,
                [styles.justifyStart]: textPosition === 'Left' && !singleColumn,
              })}
            >
              {!singleColumn && renderRichTextImage()}
              {renderTextContent()}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

export default RichTextBlock;
