import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';

export const Wrapper = styled.div<{
  $textPosition?: 'Left' | 'Right';
  $alignSelf?: 'start' | 'center' | 'end' | 'baseline';
}>`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  margin: 0 auto;

  ${({ $alignSelf }) => `align-self: ${$alignSelf};`};

  ${above.md`
    margin: ${({ $textPosition }) => ($textPosition === 'Left' ? '0 auto 0 0' : '0 0 0 auto')};
    justify-self: ${({ $textPosition }) => ($textPosition === 'Left' ? 'flex-start' : 'flex-end')};
    align-items: flex-start;
    order: ${({ $textPosition }) => $textPosition === 'Left' && -1};
  `}
`;
