import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

export const HalfWidthContainer = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  padding: 0 ${spacing.x2};
  margin-bottom: ${spacing.x3};
  margin-top: ${spacing.x3};

  ${above.sm`
    padding: 0 ${spacing.x3};
  `}

  ${above.md`
    &.ml-auto {
      padding-right: ${spacing.x15};
    }

    &.mr-auto {
      padding-left: ${spacing.x15};
    }
  `}
  ${above.lg`
    padding-left: ${spacing.x5};
    padding-right: ${spacing.x5};
  `}
`;
