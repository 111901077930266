'use client';

import Button from '@/newComponents/Button/Button';
import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';

import OfferList from '@/newComponents/CmsBlocks/OfferCardsBlock/OfferList';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import type {
  BlockItemProps,
  BlockListItem,
  GtmLineItem,
  MetaData,
  Offer,
  OfferCardsBlock as OfferCardsBlockType,
  OfferListItem,
  Product,
} from '@/types';
import { useMemo, useRef, useState } from 'react';

import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useTrackListViewed } from '@/global/hooks/useTrackListViewed';
import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { CmsColorSchemeKey } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import OfferFilter from '@/components/Offers/OfferFilter';

import {
  FinePrintContainer,
  OfferCardsBlockContainer,
  OfferCardsBlockHeaderContainer,
  OfferCardsBlockSection,
  OfferCardsGrid,
  OfferCardsWithFilterGrid,
  StyledCheckmarkList,
} from './OfferCardsBlock.styled';

type OfferCardsBlockProps = {
  data: OfferCardsBlockType;
  firstBlock?: boolean;
  metaData: MetaData;
} & BlockItemProps;

const OfferCardsBlock = (props: OfferCardsBlockProps) => {
  const { data, firstBlock = false, metaData, userId } = props;

  const { buttonLink, renderDetails } = data;
  const ref = useRef(null);
  const filteredOfferCardsList = data.offerListBlocks.filter((offerListBlock) => {
    const allItemsAreShortlisted = offerListBlock.content?.offerList?.offers.every(
      (offerListItem) => offerListItem.content.shortlisted
    );
    if (allItemsAreShortlisted) {
      return true;
    }
    return offerListBlock.content?.offerList?.offers.some(
      (offerListItem) => offerListItem.content.shortlisted === offerListBlock.content.onlyDisplayShortlisted
    );
  });

  const [selectedFilter, setSelectedFilter] = useState(filteredOfferCardsList?.[0]?.content?.offerListName);

  const currentlySelectedOfferList = filteredOfferCardsList.find(
    (filteredOfferCard) => filteredOfferCard.content.offerListName === selectedFilter
  );

  const offerItems = currentlySelectedOfferList?.content.offerList.offers.reduce(
    (acc: GtmLineItem[], curr: BlockListItem<OfferListItem>, index) => {
      const offerContent = curr.content.offer as Offer;

      const product = metaData?.products?.data?.find((productData) => productData.id === offerContent.ratorPackageId);
      if (offerContent) {
        acc.push(generateGtmLineItemFromRaw(offerContent, product as Product, data.header, index));
      }
      return acc;
    },
    []
  );

  const offerListName = currentlySelectedOfferList?.content.offerList.name;
  const itemListName = data.header || offerListName || 'Offer List';

  useTrackListViewed(ref, itemListName, offerItems, selectedFilter);

  const MemoizedOfferList = useMemo(() => {
    return data.offerListBlocks.map((offerList) => (
      <OfferList
        renderDetails={renderDetails}
        key={offerList.content.offerListName}
        offerListBlock={offerList.content}
        itemListName={itemListName}
        selectedFilter={selectedFilter}
        productsData={metaData.products}
        userId={userId}
      />
    ));
  }, [data.offerListBlocks, renderDetails, itemListName, selectedFilter, metaData.products, userId]);

  if (data.offerListBlocks.length === 0) return null;

  const { complementaryUSPs, complementaryHeader } = data;

  const offerFilter = filteredOfferCardsList.length > 1 || complementaryHeader.length > 0;

  return (
    <OfferCardsBlockSection>
      <OfferCardsBlockContainer>
        <OfferCardsBlockHeaderContainer>
          {data.header && (
            <Typography
              type={firstBlock ? TypographyType.HeadingL : TypographyType.HeadingM}
              color={Color.MineShaft}
              text={data.header}
              align="center"
            />
          )}
          {data.uSps?.$values.length > 0 && (
            <StyledCheckmarkList
              data={data.uSps?.$values}
              colorScheme={CmsColorSchemeKey.Black}
              fontWeight={FontWeight.Regular}
              fontType={TypographyType.Body}
              icon="raspberry"
            />
          )}

          {offerFilter && (
            <OfferFilter
              sideBoxUsps={complementaryUSPs}
              sideBoxHeader={complementaryHeader}
              offerListBlocks={filteredOfferCardsList}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          )}
        </OfferCardsBlockHeaderContainer>

        <OfferCardsWithFilterGrid>
          <OfferCardsGrid ref={ref}>{MemoizedOfferList}</OfferCardsGrid>
        </OfferCardsWithFilterGrid>

        {data.buttonType && buttonLink?.name && buttonLink?.url && (
          <>
            <Divider />
            <Button
              color={ButtonColor.Secondary}
              data-testid="offercard-block-link-button"
              label={buttonLink?.name}
              href={buttonLink.url}
              size={ButtonSize.Small}
              centered
              asLink
            />
          </>
        )}

        {data.finePrint !== '' && (
          <FinePrintContainer>
            <Divider marginY={spacing.x2} />
            <Typography text={<HtmlFromCMS html={data.finePrint} />} align="center" type={TypographyType.Detail} />
          </FinePrintContainer>
        )}
      </OfferCardsBlockContainer>
    </OfferCardsBlockSection>
  );
};

export default OfferCardsBlock;
