import Typography from '@/newComponents/Typography/Typography';

import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';

import Radiobutton from '@/components/Radiobutton/Radiobutton';

export const Filter = styled.div`
  padding: 0;
  flex: 1 1 100%;

  ${above.sm`
    padding: 0 ${spacing.x1} ${spacing.x2} ${spacing.x1};
    flex: 0 0 400px;
    max-width: 400px;
  `}
`;

export const FilterContainer = styled.div<{ $hasBottomUspList: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: ${({ $hasBottomUspList }) => ($hasBottomUspList ? `0 ${spacing.x3}` : `0 ${spacing.x3} ${spacing.x3}`)};
`;

export const Title = styled(Typography)`
  margin-bottom: ${spacing.x2};
  color: ${Color.MineShaft};
`;

export const RadioWrapper = styled.fieldset`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  ${above.sm`
    flex-direction: column;
    flex-wrap: nowrap;
  `}
`;

export const FilterRadioWrapper = styled(Radiobutton)`
  padding: ${spacing.x3} ${spacing.x2} ${spacing.x3} ${spacing.x2};
  font-weight: 900;
  text-align: left;
  position: relative;
  border: 3px solid ${Color.Transparent};
  border-radius: ${BorderRadius.Medium};
  margin-bottom: ${spacing.x1};
  flex: 1 1 100%;
  flex-wrap: wrap;
  span {
    margin-top: 0;
  }
  &:focus {
    outline: 2px solid;
  }
  &.checked {
    border: 3px solid ${Color.Blue};
    &:before {
      display: none;
    }
  }

  &:last-child {
    margin: 0;
  }

  &:before {
    content: ' ';
    position: absolute;
    z-index: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid ${Color.DarkGray2};
    border-radius: ${BorderRadius.Medium};
  }
`;

export const OfferDuration = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: ${FontWeight.Regular};
  color: ${Color.MineShaft};
`;

export const BottomUspList = styled.li<{ $backgroundColor?: string }>`
  margin-top: ${spacing.x25};
  position: relative;
  bottom: 0;
  list-style: none;
  background-color: ${(props) => (props.$backgroundColor ? `#${props.$backgroundColor}` : `${Color.Hallon4}`)};
  width: 100%;
  padding: ${spacing.x3};
`;
