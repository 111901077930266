import styled from 'styled-components';

import { ContentWidth, above, below } from '@/global/style/breakpoint';

export const HeaderWithIngressContainer = styled.div<{
  $leftAlignDesktop: boolean;
  $leftAlignMobile: boolean;
}>`
  max-width: ${ContentWidth.textMax};
  margin: 0 auto;

  h1,
  h2,
  h3,
  span {
    text-align: center;
  }

  ${above.md`
    margin: 0;

    ${({ $leftAlignDesktop }) =>
      $leftAlignDesktop &&
      `
        h1, h2, span {
          text-align: left;
        }
    `}
  `}

  ${below.sm`
    margin: 0;

    ${({ $leftAlignMobile }) =>
      $leftAlignMobile &&
      `
        h1, h2, span {
          text-align: left;
        }
    `}
  `}
`;
