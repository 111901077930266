import Card from '@/newComponents/Card/Card';
import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import { Orientation } from '@/newComponents/CheckmarkList/CheckmarkList.types';
import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { BlockListItem, DetailedOffers, OfferList, OfferListItem, Product, ProductRatePlan } from '@/types';
import { useId, useMemo } from 'react';

import { gtmSelectItemListItem } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { getColorFromColorScheme } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';
import OffersJson from '@/pages/api/cms/offers.json';

import {
  BottomUspList,
  Filter,
  FilterContainer,
  FilterRadioWrapper,
  RadioWrapper,
  Title,
} from './DetailedOfferFilter.styled';
import FilterText from './FilterText';

const createFilterOptions = (
  offerList: OfferList,
  setSelectedFilter: (value: BlockListItem<OfferListItem>) => void,
  offerFilterName: string,
  productsData?: Product[],
  selectedFilter?: BlockListItem<OfferListItem>,
  offerListName?: string
) => {
  if (offerList?.offers.length < 1) {
    return null;
  }

  return offerList?.offers.map((option: BlockListItem<OfferListItem>) => {
    const { content } = option;
    const productData = productsData?.find((product: Product) => product.id === content.offer?.ratorPackageId);
    const isPrepaid = productData?.ratePlan === ProductRatePlan.NonContract;

    return (
      <FilterRadioWrapper
        dataTestId="detailed-offer-filter-option"
        key={content?.offer?.ratorPackageId}
        fullWidth
        hidden
        offerCallout={content.offerCallout}
        name={offerFilterName}
        value={content?.offer?.ratorPackageId}
        state={selectedFilter === option}
        setState={() => {
          if (content.offer && productData) {
            const gtmListItem = generateGtmLineItemFromRaw(content.offer, productData, offerListName);
            gtmSelectItemListItem(gtmListItem);
          }
          setSelectedFilter(option);
        }}
        radioButtonText={
          content?.offer && <FilterText offer={content?.offer} productData={productData} isPrepaid={isPrepaid} />
        }
      />
    );
  });
};

type DetailedOfferFilterProps = {
  cmsData: DetailedOffers;
  selectedFilter?: BlockListItem<OfferListItem>;
  setSelectedFilter: (value: BlockListItem<OfferListItem>) => void;
  productsData: { data: Product[] };
};

const DetailedOfferFilter = (props: DetailedOfferFilterProps) => {
  const { offers } = OffersJson;
  const { cmsData, selectedFilter, setSelectedFilter, productsData } = props;
  const { offerList, permanentTextColorScheme, permanentUsps, permanentBackgroundColor, permanentHeader } = cmsData;
  const { data: productsDataData } = productsData;

  const offerFilterName = useId();
  const filterOptions = useMemo(
    () =>
      createFilterOptions(
        offerList,
        setSelectedFilter,
        offerFilterName,
        productsDataData,
        selectedFilter,
        permanentHeader
      ),
    [offerList, selectedFilter, setSelectedFilter, offerFilterName, productsDataData, permanentHeader]
  );

  return (
    <Filter>
      <Card data-testid="detailed-offer-card-filter" bottomPadding={false} horizontalPadding={false}>
        <FilterContainer $hasBottomUspList={permanentUsps?.$values?.length > 0}>
          <Title type={TypographyType.ExpressiveL} text={offers.offerCards.chooseSurfAmount || ''} />
          {offerList.offers.length > 1 && <RadioWrapper>{filterOptions}</RadioWrapper>}
        </FilterContainer>
        {permanentUsps?.$values?.length > 0 && (
          <BottomUspList $backgroundColor={permanentBackgroundColor}>
            {permanentHeader && (
              <>
                <Typography
                  fontWeight={FontWeight.SemiBold}
                  text={permanentHeader}
                  color={
                    getColorFromColorScheme(permanentTextColorScheme)?.header
                      ? getColorFromColorScheme(permanentTextColorScheme)?.header
                      : Color.Hallon1
                  }
                  align="left"
                />
                <Divider margin={spacing.x15} />
              </>
            )}
            <CheckmarkList
              data={permanentUsps?.$values}
              colorScheme={permanentTextColorScheme}
              orientation={Orientation.Horizontal}
            />
          </BottomUspList>
        )}
      </Card>
    </Filter>
  );
};

export default DetailedOfferFilter;
