'use client';

import Button from '@/newComponents/Button/Button';
import { ButtonColor, ButtonSize } from '@/newComponents/Button/Button.utils';

import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import { Orientation } from '@/newComponents/CheckmarkList/CheckmarkList.types';
import { ContentWrapper } from '@/newComponents/CmsBlocks/Common/ContentWrapper/ContentWrapper.styled';
import HeaderWithIngress from '@/newComponents/CmsBlocks/Common/HeaderWithIngress/HeaderWithIngress';
import Divider from '@/newComponents/Divider/Divider';
import { NoticeFieldPreset } from '@/newComponents/NoticeField/NoticeField.types';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { ApiResponseError, BlockItemProps, CmsSettings, CountdownHeroBlock as CountdownHeroBlockType } from '@/types';
import { useRouter } from 'next/navigation';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { gtmSelectPromotion } from '@/global/gtm';
import { generateGtmLineItemFromRaw } from '@/global/gtm/helpers';
import { useAppContext } from '@/global/hooks/useAppContext';
import { useGetProduct } from '@/global/hooks/useGetProduct';
import useToastNotice from '@/global/hooks/useToastNotice';
import { useTrackPromotionViewed } from '@/global/hooks/useTrackPromotionViewed';
import useTracking from '@/global/hooks/useTracking';
import { useCmsContext } from '@/global/layouts/CmsProvider';
import { spacing } from '@/global/style/variables';
import { addToCart, getAddToCartError } from '@/global/utils/AddToCart';
import { getCmsTextWithValues, getCountdownCmsTheme } from '@/global/utils/Cms';
import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

import OfferCountdown from '@/components/OfferCountdown/OfferCountdown';

import { CountdownContainer, CountdownHeroBlockWrapper } from './CountdownBlock.styled';

export type CountdownBlockProps = {
  data?: CountdownHeroBlockType | null;
  firstBlock?: boolean;
} & BlockItemProps;

const CountdownHeroBlock = (props: CountdownBlockProps) => {
  const { data, firstBlock = false, userId } = props;
  const { $type, header, offer, theme: themeKey } = data || {};
  const { url: buttonLinkUrl } = data?.buttonLink || {};
  const { account } = useAppContext();
  const { type: userBrand } = account || {};
  const [isLoading, setIsLoading] = useState(false);
  const [offerPassed, setOfferPassed] = useState(false);

  const ref = useRef<HTMLElement>(null);
  const router = useRouter();
  const product = useGetProduct(data?.offer?.ratorPackageId);

  const offerItem = offer && product && generateGtmLineItemFromRaw(offer, product);
  const disableExpiredOffer = offerPassed && data?.disableLinkWhenCountdownReachesZero;
  const { settings } = useCmsContext<{ settings: CmsSettings }>();
  const { errorMessages } = settings;
  const { utmTracking, salesTracking } = useTracking();

  useTrackPromotionViewed(ref, $type, header, !!data?.offer, offerItem);
  const toast = useToastNotice();

  useEffect(() => {
    const delta = data && data.endDate ? Date.parse(data.endDate) - Date.now() : Date.now();
    const id = setTimeout(() => {
      setOfferPassed(true);
    }, delta);
    return () => clearTimeout(id);
  }, [data?.endDate, data]);

  const handleClick = async () => {
    if ($type && header) gtmSelectPromotion($type, header, offerItem || null);

    if (!offer || !product) {
      if (buttonLinkUrl) {
        return router.push(buttonLinkUrl);
      }
      return;
    }

    try {
      setIsLoading(true);

      await addToCart({
        offer,
        productData: product,
        brand: product.brand,
        userId,
        utmTracking,
        salesTracking,
      });

      router.push('/checkout');
    } catch (error) {
      setIsLoading(false);
      console.error('Failed to add product to cart', error); // eslint-disable-line no-console
      toast({
        dataTestId: 'add-to-cart-error-toast',
        preset: NoticeFieldPreset.Error,
        ...getAddToCartError(error as ApiResponseError, errorMessages, userBrand),
      });
    }
  };

  const handleHeroClick = (event: SyntheticEvent) => {
    if (!data || isLoading || disableExpiredOffer) return null;

    // if the user clicks the button, we'll end up here afterwards, stop things from that point on
    if (data.onlyButtonIsClickable) {
      event.stopPropagation();
      return null;
    }

    handleClick();
  };

  // Todo: null check should be done in the parent component BlockList.tsx, required props passed to the Hero should always be set
  // Also rewrite that component as part of New identity
  if (!data) return null;

  const theme = getCountdownCmsTheme(themeKey);
  const { backgroundColor, foregroundColor } = theme;

  const clickIsDisabled = Boolean(offerPassed && data.disableLinkWhenCountdownReachesZero);
  const blockHasLinkOrOffer = buttonLinkUrl || offer;

  return (
    <CountdownHeroBlockWrapper
      $backgroundColor={backgroundColor}
      $pointer={Boolean(!data.onlyButtonIsClickable && !disableExpiredOffer && !clickIsDisabled && blockHasLinkOrOffer)}
      onClick={handleHeroClick}
    >
      <ContentWrapper>
        <HeaderWithIngress
          header={data.header}
          headerAsH1={firstBlock}
          ingress={data.subheader}
          textColor={foregroundColor}
          leftAlignDesktop
        />

        <CountdownContainer>
          <Typography text={data.countdownHeader} type={TypographyType.ExpressiveL} color={foregroundColor} />
          <Divider />
          <OfferCountdown endDate={data.endDate} />

          <Divider marginY={spacing.x2} />

          {data.usps?.$values.length > 0 && (
            <>
              <CheckmarkList
                data={data.usps?.$values}
                foregroundColor={foregroundColor}
                orientation={Orientation.Vertical}
              />
              <Divider marginY={spacing.x2} />
            </>
          )}

          {data.buttonText && blockHasLinkOrOffer && (
            <Button
              data-testid="countdown-hero-block-button"
              label={<HtmlFromCMS html={getCmsTextWithValues(data.buttonText)} />}
              color={data.buttonType || ButtonColor.Primary}
              isLoading={isLoading}
              disabled={clickIsDisabled}
              transparentBackground={theme.transparentSecondaryButton}
              onClick={handleClick}
              size={ButtonSize.Small}
            />
          )}
        </CountdownContainer>
      </ContentWrapper>
    </CountdownHeroBlockWrapper>
  );
};

export default CountdownHeroBlock;
