import styled from 'styled-components';

import { spacing } from '@/global/style/variables';

export const IconWithLabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${spacing.x1};
  align-items: center;

  // adjust label slightly down due to its line height
  span {
    position: relative;
    top: 1px;
  }
`;
