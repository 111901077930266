import Divider from '@/newComponents/Divider/Divider';
import { type ButtonProp } from '@/newComponents/GroupedButtons/GroupedButtons';
import { TypographyType } from '@/newComponents/Typography/Typography.types';
import { BlockListItem, OfferListBlock, UspsWithoutIcons } from '@/types';
import { useMemo } from 'react';

import { Color, spacing } from '@/global/style/variables';
import { FontWeight } from '@/constants';
import { CmsColorSchemeKey } from '@/global/utils/CmsBlocks/GetColorFromColorScheme';

import { Filter, StyledCheckmarkList, StyledGroupedButtons, Title } from '@/components/Offers/OfferFilter.styled';

const offerListBlocksToButtonGroupMapper = (offerListBlocks: BlockListItem<OfferListBlock>[]): ButtonProp[] => {
  return offerListBlocks.map((offerListBlock) => ({
    section: offerListBlock.content.offerListName,
    name: offerListBlock.content.offerListName,
  }));
};

type OfferFilterProps = {
  sideBoxHeader?: string;
  sideBoxUsps: UspsWithoutIcons;
  className?: string;
  offerListBlocks: BlockListItem<OfferListBlock>[];
  selectedFilter?: string;
  setSelectedFilter: (filter: string) => void;
};

const OfferFilter = (props: OfferFilterProps) => {
  const { sideBoxHeader, sideBoxUsps, className, offerListBlocks, selectedFilter, setSelectedFilter } = props;
  const groupedButtonProps = useMemo(() => offerListBlocksToButtonGroupMapper(offerListBlocks), [offerListBlocks]);
  return (
    <Filter className="offer-filter">
      {sideBoxHeader && <Title text={sideBoxHeader} type={TypographyType.ExpressiveL} color={Color.MineShaft} />}
      {sideBoxUsps?.$values?.length > 0 && (
        <StyledCheckmarkList
          data={sideBoxUsps?.$values}
          fontWeight={FontWeight.Regular}
          colorScheme={CmsColorSchemeKey.Black}
          fontType={TypographyType.Body}
          icon="raspberry"
        />
      )}
      <Divider marginYSm={spacing.x25} marginY={spacing.x2} />
      <StyledGroupedButtons
        className={className}
        buttonProps={groupedButtonProps}
        onClick={(view) => setSelectedFilter(view.name)}
        selectedTab={selectedFilter || ''}
      />
    </Filter>
  );
};

export default OfferFilter;
