import { createGlobalStyle, css } from 'styled-components';
import { FontWeight } from '@/constants';
import adyen from './adyen';
import fonts from './fonts';
import linkStyles from './linkStyles';
import normalize from './normalize';
import nprogress from './nprogress';
import { Color } from './variables';

const Styles = createGlobalStyle`
  ${fonts}
  ${normalize}
  ${nprogress}
  ${adyen}
  ${linkStyles}

  button {
    margin: 0;
  }

  span {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  * *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'BagossStandard', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: ${FontWeight.Regular};
    color: black;
    line-height: 1;
    text-size-adjust: 100%;
    background: ${Color.Gray2};
  }

  main {
    display: block;
  }

  .base-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1000;
  }

  svg {
    width: 100%;
    height: 100%;
  }
  b, strong {
    font-weight: ${FontWeight.Medium};
  }
`;

export default Styles;

export const BackgroundGradient = css`
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 80px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 100%);
  }
`;
