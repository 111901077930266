import Divider from '@/newComponents/Divider/Divider';
import Typography from '@/newComponents/Typography/Typography';
import { TypographyType } from '@/newComponents/Typography/Typography.types';

import { HeaderWithIngressContainer } from './HeaderWithIngress.styled';
import { HeaderWithIngressProps } from './HeaderWithIngress.types';

const HeaderWithIngress = (props: HeaderWithIngressProps) => {
  const {
    className,
    header,
    headerAsH1 = false,
    headerType = TypographyType.HeadingM,
    textColor,
    leftAlignDesktop = false,
    leftAlignMobile = false,
    ingress,
    ingressTypographyType = TypographyType.ExpressiveL,
  } = props;

  return (
    <HeaderWithIngressContainer
      className={className}
      $leftAlignDesktop={leftAlignDesktop}
      $leftAlignMobile={leftAlignMobile}
    >
      <Typography text={header} type={headerAsH1 ? TypographyType.HeadingL : headerType} color={textColor} />

      {ingress && (
        <>
          <Divider />
          <Typography
            text={ingress as string}
            elementAs="span"
            align="center"
            type={ingressTypographyType}
            color={textColor}
          />
        </>
      )}
    </HeaderWithIngressContainer>
  );
};

export default HeaderWithIngress;
