import Badge from '@/newComponents/Badge/Badge';
import styled from 'styled-components';

import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const Label = styled.label<{ $disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  padding-right: calc(${spacing.x05} * 0.5); // 2px
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledBadge = styled(Badge)`
  margin-top: ${spacing.x2};
  z-index: 1;
  align-self: flex-start;

  width: 100%;
`;

export const RadiobuttonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OuterWrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '')};
`;

export const CircleSpan = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: ${BorderRadius.Small};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenRadiobutton = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &.focus-visible + div::after {
    content: '';
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    z-index: 1;
    top: -3px;
    left: -3px;
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

export const StyledRadiobutton = styled.div<{ $hidden?: boolean; $disabled?: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  border: ${({ $disabled }) => `2px solid ${$disabled ? Color.Gray1 : Color.Blue}`};
  background: ${Color.White};
  border-radius: 50%;
  transition: all 150ms;
  box-shadow: '';
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  &.checked {
    background: ${Color.Blue};
    box-shadow: 0 0 0 3px ${Color.Sky};
    ${CircleSpan} {
      visibility: visible;
    }
  }

  [disabled] & {
    border-color: ${Color.DarkGray2};
    color: ${Color.DarkGray2};
  }
`;

export const Text = styled.span`
  margin-left: ${spacing.x2};
  width: 100%;

  [disabled] & {
    * {
      color: ${Color.DarkGray2};
    }
  }
`;
