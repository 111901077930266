'use client';

import {
  type BlockListItem,
  type BlockListItemType,
  ComponentBlockType,
  NonComponentBlockType,
  type ReusableBlock,
} from '@/types';
import { ReactNode } from 'react';

import { useAppContext } from '@/global/hooks/useAppContext';

import HeadlineBlock from '@/newComponents/CmsBlocks/HeadlineBlock/HeadlineBlock';
import FullWidthTeaserBlock from '@/newComponents/CmsBlocks/FullWidthTeaserBlock/FullWidthTeaserBlock';
import UspBlock from '@/newComponents/CmsBlocks/UspBlock/UspBlock';
import HalfWidthTeaserBlock from '@/newComponents/CmsBlocks/HalfWidthTeaserBlock/HalfWidthTeaserBlock';
import HalfWidthImageBlock from '@/newComponents/CmsBlocks/HalfWidthImageBlock/HalfWidthImageBlock';
import FullWidthRoundedImageTeaserBlock from '@/newComponents/CmsBlocks/FullWidthRoundedImageTeaserBlock/FullWidthRoundedImageTeaserBlock';
import MiniTeasersBlock from '@/newComponents/CmsBlocks/MiniTeasersBlock/MiniTeasersBlock';
import RichTextBlock from '@/newComponents/CmsBlocks/RichTextBlock/RichTextBlock';
import CountdownBlock from '@/newComponents/CmsBlocks/CountdownBlock/CountdownBlock';
import Bannerflow from '@/components/Bannerflow/Bannerflow';
import RawHtmlBlock from '@/components/BlockTypes/RawHtmlBlock';
import DetailedOfferCardsBlock from '@/newComponents/CmsBlocks/DetailedOfferCardsBlock/DetailedOfferCardsBlock';
import OfferCardsBlock from '@/newComponents/CmsBlocks/OfferCardsBlock/OfferCardsBlock';
import CrossSellingBlock from '@/newComponents/CmsBlocks/CrossSellingBlock/CrossSellingBlock';
import StickyUspBanner from '@/newComponents/CmsBlocks/StickyUspBanner/StickyUspBanner';
import LegalBlock from '@/newComponents/CmsBlocks/LegalBlock/LegalBlock';

import { BlockListWrapper } from './BlockList.styled';

const blockTypes: Record<ComponentBlockType, unknown> = {
  [ComponentBlockType.HeadlineBlock]: HeadlineBlock,
  [ComponentBlockType.FullWidthTeaserBlock]: FullWidthTeaserBlock,
  [ComponentBlockType.UspBlock]: UspBlock,
  [ComponentBlockType.HalfWidthTeaserBlock]: HalfWidthTeaserBlock,
  [ComponentBlockType.HalfWidthImageBlock]: HalfWidthImageBlock,
  [ComponentBlockType.FullWidthRoundedImageTeaserBlock]: FullWidthRoundedImageTeaserBlock,
  [ComponentBlockType.MiniTeasersBlock]: MiniTeasersBlock,
  [ComponentBlockType.RichTextBlock]: RichTextBlock,
  [ComponentBlockType.CountdownHeroBlock]: CountdownBlock,
  [ComponentBlockType.HeroBannerflowBlock]: Bannerflow,
  [ComponentBlockType.RawHtmlblock]: RawHtmlBlock,
  [ComponentBlockType.DetailedOffers]: DetailedOfferCardsBlock,
  [ComponentBlockType.OfferCardsBlock]: OfferCardsBlock,
  [ComponentBlockType.CrossSellingBlock]: CrossSellingBlock,
  [ComponentBlockType.StickyUspBanner]: StickyUspBanner,
  [ComponentBlockType.LegalBlock]: LegalBlock,
};

const halfWidthBlocks = [ComponentBlockType.HalfWidthTeaserBlock, ComponentBlockType.HalfWidthImageBlock];

type BlockListItemProps = {
  blocks: BlockListItem<BlockListItemType>[];
  firstHeaderAsH1?: boolean;
  metaData?: unknown;
};

const BlockList = ({ blocks, firstHeaderAsH1, metaData }: BlockListItemProps) => {
  const { userId, pageBrand } = useAppContext();

  const renderBlocks = (blockItems: BlockListItemProps['blocks'], previouslyIndexedBlocks = 0): ReactNode | boolean => {
    let halfWidthIndex = 0;
    return blockItems?.map((block, _index) => {
      const index = previouslyIndexedBlocks + _index;
      let halfBlockClassName;
      const blockType = block?.content?.$type;

      if (blockType === NonComponentBlockType.ReusableBlock) {
        // ResusableBlock is basically another list of blocks, so call RenderBlocks recursively
        return renderBlocks((block.content as ReusableBlock).contentBlock.contentBlock, index);
      }

      const Block = blockTypes[blockType];

      if (!Block) {
        console.log(`Block type ${blockType} not found`); // eslint-disable-line no-console
        return null;
      }

      if (halfWidthBlocks.includes(blockType)) {
        halfBlockClassName = `half-block${halfWidthIndex % 2 ? ' mr-auto' : ' ml-auto'}`;
        halfWidthIndex += 1;
      }

      return (
        // @ts-expect-error Props for each block type is different, so we can't type it?
        <Block
          firstBlock={firstHeaderAsH1 && index === 0}
          className={halfBlockClassName}
          key={block.contentUdi}
          id={block.contentUdi.split('umb://element/')[1]}
          data={block.content}
          userId={userId}
          pageBrand={pageBrand}
          metaData={metaData}
          imageHasPriority={index <= 1}
        />
      );
    });
  };

  if (!blocks?.length) return null;

  return <BlockListWrapper>{renderBlocks(blocks)}</BlockListWrapper>;
};

export default BlockList;
