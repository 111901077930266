import LazyLoadingImage from '@/newComponents/LazyLoadingImage/LazyLoadingImage';
import StickerPair from '@/newComponents/Sticker/StickerPair';
import styled, { css } from 'styled-components';

import { ContentWidth, above } from '@/global/style/breakpoint';
import { NewBorderRadius, spacing } from '@/global/style/variables';

export const ImageWrapper = styled.div<{ $textPosition?: 'Left' | 'Right'; $squareImageOnly?: boolean }>`
  position: relative;

  justify-self: center;

  width: 296px;
  height: 296px;

  ${above.xs`
    width: 320px;
    height: 320px;
  `};

  ${({ $squareImageOnly }) =>
    !$squareImageOnly &&
    css`
      ${above.md`
        justify-self: ${({ $textPosition }) => ($textPosition === 'Left' ? 'flex-end' : 'flex-start')};
      `}
      ${above.lg`
        width: inherit;
        max-width: ${ContentWidth.textMax};
        min-width: 320px;
      `};

      ${above.xlg`
        width: ${ContentWidth.textMax};
      `}
    `};
`;

export const StyledImage = styled(LazyLoadingImage)`
  object-fit: cover;
  overflow: hidden;
  border-radius: ${NewBorderRadius.Medium};
`;

export const StyledStickerPair = styled(StickerPair)<{
  $stickerOffsetTop: string;
  $stickerOffsetRight: string;
  $centered: boolean;
}>`
  position: absolute;
  bottom: ${({ $stickerOffsetTop }) => `calc(-${spacing.x7} - ${$stickerOffsetTop})`};

  ${({ $centered, $stickerOffsetRight }) => {
    if ($centered) {
      return 'justify-content: center';
    }

    // return `right: -${spacing.x2};`;
    return `right: calc(-${spacing.x2} - ${$stickerOffsetRight})`;
  }};

  ${above.md`
    bottom: ${({ $stickerOffsetTop }) => `calc(-${spacing.x8} - ${$stickerOffsetTop})`};
    ${({ $centered, $stickerOffsetRight }) => {
      if ($centered) {
        return 'justify-content: center';
      }

      return `right: calc(-${spacing.x3} - ${$stickerOffsetRight})`;
    }};
  `};
`;
