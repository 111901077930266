import styled from 'styled-components';

import HtmlFromCMS from '@/global/utils/HtmlFromCMS';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

type RawHtmlBlockProps = {
  data: { rawHtml: string };
};

const RawHtmlBlock = ({ data }: RawHtmlBlockProps) => {
  return (
    <StyledSection>
      <HtmlFromCMS html={data.rawHtml} />
    </StyledSection>
  );
};

export default RawHtmlBlock;
