import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

export const Container = styled.section<{ $backgroundColor: string }>`
  flex: 1 1 100%;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.x7} 0;

  ${above.sm`
    padding: ${spacing.x9} 0;
  `};

  ${above.xlg`
    padding: ${spacing.x10} 0;
  `};
`;

export const IconList = styled.ul`
  display: flex;
  width: 100%;
  gap: ${spacing.x2};
  flex-direction: column;
  margin-bottom: ${spacing.x3};

  ${above.sm`
    flex-direction: row;
    align-self: center;
    margin-bottom: ${spacing.x4};
  `}
`;

export const IconListItem = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${spacing.x3};
  flex-direction: column;
`;

export const UspIcon = styled.img`
  ${above.sm`
    width: 96px;
    height: 96px;
  `}
`;
