import CheckmarkList from '@/newComponents/CheckmarkList/CheckmarkList';
import GroupedButtons from '@/newComponents/GroupedButtons/GroupedButtons';
import Typography from '@/newComponents/Typography/Typography';
import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { spacing } from '@/global/style/variables';

export const Filter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: ${spacing.x1};

  ${above.sm`
    align-items: center;
  `}
`;

export const Title = styled(Typography)`
  margin: 0 0;
`;

export const StyledCheckmarkList = styled(CheckmarkList)`
  && {
    margin: ${spacing.x2} ${spacing.x2} 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${above.sm`
      display: unset;
      margin: ${spacing.x2} auto 0;
    `};
  }
`;

export const StyledGroupedButtons = styled(GroupedButtons)`
  margin-bottom: 0;
`;
