import { ContentWrapper as CommonContentWrapper } from '@/newComponents/CmsBlocks/Common/ContentWrapper/ContentWrapper.styled';
import StickerPair from '@/newComponents/Sticker/StickerPair';
import styled, { css } from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { GridLargeWrapper } from '@/global/style/extends/Grid';
import { Color, NewBorderRadius, spacing } from '@/global/style/variables';

export const Background = styled.section<{
  $backgroundImage?: string;
  $backgroundIsClickable?: boolean;
  $backgroundColor?: string;
  $negativeMargin?: boolean;
  $bleed?: boolean;
}>`
  display: grid;
  width: 100%;
  cursor: ${({ $backgroundIsClickable }) => ($backgroundIsClickable ? 'pointer' : 'default')};
  position: relative;
  z-index: 0;

  ${({ $bleed, $backgroundColor }) =>
    $bleed
      ? css`
          background-color: ${$backgroundColor || Color.Transparent};
        `
      : css`
          ${GridLargeWrapper};
          border-radius: ${NewBorderRadius.Large};
          justify-self: center;

          margin-bottom: ${spacing.x7};
          margin-top: ${spacing.x7};

          ${above.md`
            margin-bottom: ${spacing.x9};
            margin-top: ${spacing.x9};
          `};
        `}
  ${({ $negativeMargin }) =>
    $negativeMargin &&
    css`
      margin-bottom: calc(-${spacing.x1} * 15); // -160px

      ${above.sm`
        margin-bottom: calc(-${spacing.x1} * 13); // -144px
      `}
    `};
`;

export const ContentWrapper = styled(CommonContentWrapper)<{ $bleed: boolean; $backgroundColor?: string }>`
  padding-left: ${spacing.x2};
  padding-right: ${spacing.x2};

  ${above.md`
    padding-left: ${spacing.x5};
    padding-right: ${spacing.x5};
  `}

  ${({ $bleed, $backgroundColor }) =>
    $bleed
      ? css`
          position: unset;
          ${GridLargeWrapper}
        `
      : css`
          background-color: ${$backgroundColor || Color.Transparent};
          border-radius: ${NewBorderRadius.Large};
          overflow: hidden;
        `};
`;

export const StyledStickerPair = styled(StickerPair)<{
  $textPosition?: 'Left' | 'Right';
}>`
  justify-content: center;
  ${above.md`
    justify-content: ${({ $textPosition }) => ($textPosition === 'Left' ? 'flex-end' : 'flex-start')};
  `}
`;
