import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { BorderRadius, Color, spacing } from '@/global/style/variables';

export const Wrapper = styled.div<{ $alignLeft?: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, min-content);
`;

export const CountdownFraction = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  justify-content: center;
  align-content: center;
  background: ${Color.White};
  border-radius: ${BorderRadius.Medium};
  width: 82px;
  height: 110px;
  margin-right: ${spacing.x1};

  &:last-child {
    margin-right: 0;
  }

  ${above.sm`
		width: 108px;
		height: 138px;
  `}
`;
