import { HeadlineBlockTheme } from '@/types';
import styled from 'styled-components';

import { above } from '@/global/style/breakpoint';
import { Color, spacing } from '@/global/style/variables';

const themeBg = {
  [HeadlineBlockTheme.White]: Color.White,
  [HeadlineBlockTheme.Grey]: Color.Gray2,
};

export const HeadlineBlockWrapper = styled.section<{ $theme?: HeadlineBlockTheme }>`
  width: 100%;
  background-color: ${({ $theme }) => ($theme ? themeBg[$theme] : Color.Gray2)};
  padding: ${spacing.x7} 0 ${spacing.x4} 0;

  ${above.sm`
    padding: ${spacing.x7} 0 ${spacing.x6} 0;
  `}
`;

// This wrapper is needed since our BlockListWrapper is setting max-width to it's children
export const IngressWrapper = styled.div`
  display: grid;
  place-items: center;
`;
